/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useEffect, useState } from 'react';
//import config from '../data/config.json';
import { makeStyles } from '@material-ui/core/styles';
import RenderChart from './RenderChart';

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "2%",
        marginLeft: "2%",
        justifyContent: "space-between"
    }
}))


function Container(props) {
    const classes = useStyles();
    const [renderedChart, setRenderedChart] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        getData();
        setFilter(props.filterVal);
    }, [props])

    //API call
    const getData = () => {
        let arr = [];
        Object.keys(props.config.chartConfig).map((i, index) => {
            arr.push({ chartConfig: props.config.chartConfig[i], index: index })
        })
        setRenderedChart(arr);
    }

    return (
        <div className={classes.flexContainer}>
            {renderedChart.map((i, j) => {
                return <RenderChart key={j} channel={props.channel} filterVal={filter} apiHeader={props.config.authAPI.authHeaders} config={i.chartConfig} index={i.index + 1} tab={props.index} />

            })}
        </div>
    )
}

export default Container;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Area, Legend, CartesianGrid, Bar, Line } from 'recharts';
import stylesConfig from '../../data/stylesConfig.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    download: {
        float: "right",
        margin: "-50px 40px 0px 0px"
    },
    tooltip: {
        backgroundColor: "white",
        fontSize: "12px",
        padding: "10px"
    },
    title: {
        fontSize: "18px",
        margin: "15px"

    },
    message: {
        fontSize: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
        color: "red"

    }
}))

export default function App(props) {
    const classes = useStyles();

    const [Data, setData] = useState([]);
    //const color = props.chartConfig.color;
    useEffect(() => {
        // let arr = [];
        setData(props.data)
    }, [props])
    // useEffect(() => {
    //     let data = [];
    //     data = props.data.sort(function (a, b) {
    //         return new Date(a.day) - new Date(b.day);
    //     });
    //     let chartData = [];
    //     data.map(i => {
    //         return chartData.push({
    //             day: i.day.substring(0, i.day.length - 5),
    //             Totalcount: i.Totalcount,
    //             successcount: i.successcount
    //         })
    //     })
    //     setData(chartData);
    // }, [props])
    console.log(Data);
    const labelKey = {
        "totalSdCalls": `${props.label}`,
        "smartIvrCalls": "Smart IVR calls",
        "unresolved": "Unresolved calls",
        "resolved": "Resolved calls"
    }

    //Add customised tooltip in this chart
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className={classes.tooltip}>
                    <div>{label}</div>
                    {payload?.map(e => {
                        return <div className="label">{`${labelKey[e.name] + ":" + e.value}`}</div>
                    })}
                </div>
            );
        }
        return null;
    }


    return (
        <div className="App">
            {/* <div className={classes.title}>{props.title}</div> */}
            {Data.length !== 0 ? <ComposedChart
                width={stylesConfig.barAndLine.chartWidth}
                height={stylesConfig.barAndLine.chartHeight}
                data={Data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis tick={{ fontSize: "13px" }} dataKey="date" />
                <YAxis tick={{ fontSize: "13px" }} />
                <Tooltip content={<CustomTooltip />} />
                <Legend height={14} payload={
                    [
                        { id: 'totalSdCalls', value: `${props.label}`, type: 'square', color: "#87CEFA" },
                        { id: 'smartIvrCalls', value: `Smart IVR ${props.legend} calls`, type: 'line', color: "#65C0BA" },
                        { id: 'resolved', value: `Resolved ${props.legend} calls`, type: 'square', color: "#216583" },
                        { id: 'unresolved', value: `Unresolved ${props.legend} calls`, type: 'square', color: "#F76262" },
                    ]
                } />
                <Legend style={{ marginTop: "20px" }} />
                {props.showExcelData && <Area type="monotone" dataKey="totalSdCalls" fill="#87CEFA" stroke="#87CEFA" />}
                <Line type="monotone" dataKey="smartIvrCalls" stroke="#65C0BA" />
                <Bar dataKey="unresolved" key="1" stackId="a" barSize={20} fill="#F76262" />
                <Bar dataKey="resolved" key="2" stackId="a" barSize={20} fill="#216583" />
            </ComposedChart> : <div className={classes.message}>SD Data not available</div>}
        </div>
    );
}
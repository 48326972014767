/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import stylesConfig from '../../data/stylesConfig.json';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    download: {
        float: "right",
        margin: "-50px 40px 0px 0px"
    },
    tooltip: {
        backgroundColor: "white",
        fontSize: "12px",
        padding: "10px"
    },
    notFound: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "120px",
        fontSize: "20px",
        color: "#0077b6"
    },
}))

function TicketStackedBarChart(props) {
    const classes = useStyles();
    const [formatedData, setFormatedData] = useState([]);
    const [download, setDownload] = useState([]);
    console.log(download);


    //set colors in chart
    const fillColor = (index) => {
        let arr = [];
        arr = props.chartConfig.color.map(i => i)
        return arr[index]
    }

    useEffect(() => {
        formatData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const labelKeyName = props.chartConfig.labelKeyName;
    const renderColorfulLegendText = (value,entry) => {
        return <span style={{color:'black'}}>{value}</span>;
      };
    //Cutomised tooltip
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className={classes.tooltip}>
                    <div>{label}</div>
                    {payload?.map(e => {
                        return <div className="label">{`${labelKeyName[e.name] + ":" + e.value}`}</div>
                    })}
                </div>
            );
        }
        return null;
    };

    // const color = props.chartConfig.color;

    //Sort by dates
    const formatData = () => {
        // let res = [];
        // res = props.data.sort(function (a, b) {
        //     return new Date(a.day) - new Date(b.day);
        // });

        //API Call
        setFormatedData(props.data);
        if (props.filter === 7 || props.filter === 30) {
            setDownload(props.data)
        } else {
            const basicAuth = props.header;
            axios.get(`${props.chartConfig.downloadUrl}/${props.filter}`, {
                auth: {
                    username: "admin",
                    password: basicAuth
                }
            })
                .then((res) => {
                    res.data.sort(function (a, b) {
                        return new Date(a.day) - new Date(b.day);
                    })
                    setDownload(res.data);
                    return res.data;
                })
        }
    }
    const formatXAxis = (val) => {
        val=val.toString();
        switch(val)
        {
            case "passwordReset":
                return "Password Reset"
            case "retailStore":
                return "Retail Store"
            case "otherBusinessArea":
                return "Other Business Areas"
            default:
                return ""

        }
    }

    const payloadData = () => {
        let obj = Object.keys(props.chartConfig.labelKeyName).map((i, index) => {
            return { "id": i, "value": props.chartConfig.labelKeyName[i], "type": "square", "color": props.chartConfig.color[index] }
        })
        return obj;
    }
    return (
        <>

            {/* {download.length !== 0 && <CSVLink className={classes.download} filename={`CallSummary_${props.date}.csv`} headers={headers} data={download} separator={","}>
                <GetAppIcon style={{ fill: "#0077b6" }} />
            </CSVLink>} */}

            <BarChart
                width={stylesConfig.skillwiseSummary.chartWidth}
                height={stylesConfig.skillwiseSummary.chartHeight}
                // data={formatedData}
                data={formatedData}
                margin={{
                    top: 20, right:30, left:0, bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={{ fontSize: "13px" }} minTickGap={1} tickFormatter={formatXAxis} height={45} dataKey="name" />
                <YAxis tick={{ fontSize: "13px" }} />
                {/* <Tooltip />
                <Legend /> */}
                <Tooltip content={<CustomTooltip />} />
                <Legend  height={14} payload={payloadData()} align="center" width="100%" formatter={renderColorfulLegendText}/>

                {props.chartConfig.yaxis.map((i, index) => {
                    return <Bar dataKey={i} key={index} barSize={60} stackId="a" fill={fillColor(index)} />
                })}
            </BarChart>
        </>
    );
}

export default TicketStackedBarChart;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 80,
        marginTop: "-17px",
        marginLeft:'-30px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),

    },
}));

function UseCaseFilter(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState("All");

    //Update current value on change in filter
    const handleChange = (event) => {
        setAge(event.target.value);
        props.useCaseFilter(event.target.value);
    };
    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Filter
                </InputLabel>
                <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                >
                    {Object.keys(props.config.useCaseFilter).map((i,index) => {
                        return <MenuItem value={props.config.useCaseFilter[i]} key={index}>{i}</MenuItem>
                    })}
                    {/* <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"PasswordReset"}>Password Reset</MenuItem>
                    <MenuItem value={"TicketStatus"}>Ticket Status</MenuItem> */}
                </Select>
                {/* <FormHelperText>Filter by use case</FormHelperText> */}
            </FormControl>
        </>
    )
}

export default UseCaseFilter;
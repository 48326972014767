/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';
import { Typography } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        marginLeft: "0px"
    },
    root1: {
        width: '100%',
        maxWidth: 560,
        backgroundColor: theme.palette.background.paper,
        marginLeft: "15px",
        // padding: "15px"
    },
    float: {
        float: "right"
    },
    download: {
        float: "right",
        margin: "-50px 0px 0px 0px"
    },
    notFound: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "120px",
        fontSize: "20px",
        color: "#0077b6"
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function SimpleList(props) {
    const classes = useStyles();
    const [download, setDownload] = useState([]);
    const [formatedData, setFormatedData] = useState([]);

    useEffect(() => {
        downloadData(props.filter);
        props.tab !== 4 ? formatData() : formatData2()
    }, [props])

    //get data in required format
    const formatData = () => {
        let result = [];

        props.data.map(key => {

            let currentIndex = result.findIndex((i) => i.question === key.reasonOfFailure)
            if (currentIndex < 0) {
                result.push({
                    reason: key.reasonOfFailure,
                    count: 0,
                })
                currentIndex = result.length - 1;
            }
            result[currentIndex].count = result[currentIndex].count + key.count;
        })
        result.sort((a, b) => parseInt(b.count) - parseInt(a.count))
        console.log(result)
        setFormatedData(result);
    }

    const formatData2 = () => {
        let result = [];

        props.data.map(key => {

            let currentIndex = result.findIndex((i) => i.reason === key.reason)
            if (currentIndex < 0) {
                result.push({
                    reason: key.reason,
                    count: 0,
                })
                currentIndex = result.length - 1;
            }
            result[currentIndex].count = result[currentIndex].count + key.count;
        })
        result.sort((a, b) => parseInt(b.count) - parseInt(a.count))
        // console.log(result)
        setFormatedData(result);
    }

    //download data to csv file
    const downloadData = (filter) => {
        let downloadRes = [];
        if (filter === 7 || filter === 30) {
            props.data.sort(function (a, b) {
                return new Date(a.period) - new Date(b.period);
            });
            props.data.map(i => {
                downloadRes.push({
                    day: i.period,
                    reasonOfFailure: i.reasonOfFailure,
                    count: i.count
                })
            })
            setDownload(downloadRes);
        } else {
            const basicAuth = props.header;
            axios.get(`${props.chartConfig.downloadUrl}/${filter}`,
                { headers: { "Authorization": basicAuth } },
                //      {
                //     auth: {
                //         username: "admin",
                //         password: basicAuth
                //     }
                // }
            )
                .then((res) => {
                    res.data.sort(function (a, b) {
                        return new Date(a.day) - new Date(b.day);
                    });
                    setDownload(res.data);
                    return res.data;
                })
        }
    }
    const headers = [
        { key: "day", label: "date" },
        { key: "reasonOfFailure", label: "Reasons for handing over the call to agent" },
        { key: "count", label: "Number of calls handed over to agent" },
    ]
    return (
        <div className={props.channel !== "email" ? props.tab !== 4 ? classes.root : classes.root1 : classes.root1}>
            {download.length !== 0 && <CSVLink className={classes.download} filename={`AgentHandoverReasons_${props.date}.csv`} headers={headers} data={download} separator={","}>
                <GetAppIcon style={{ fill: "#0077b6" }} />
            </CSVLink>}
            <div>
                <List>
                    <ListItemLink style={{ marginTop: "-15px" }}>
                        <ListItemText primary={<Typography style={{ fontWeight: "bold" }}>Reasons</Typography>} />
                        <div style={{ fontWeight: "bold" }}>Count</div>
                    </ListItemLink>
                </List>

                <Divider />
                <List component="nav" aria-label="secondary mailbox folders">
                    {formatedData.map((i) => <>
                        {i.reason !== null &&
                            <>
                                <ListItemLink key={i.count}>
                                    <ListItemText key={i.reason} primary={i.reason} />
                                    <div key={i.count} className={classes.float}>
                                        {i.count}
                                    </div>
                                </ListItemLink>
                            </>}
                        <Divider />
                    </>
                    )}
                </List>
            </div>
            <div style={{ fontSize: "12px", color: "#8E8E8E", padding: '5px 5px' }}>{props.tab !== 4 ? "(Others include Timeout, DTMF Input not Identified, Termination of Calls before Use Case Identification, User being silent in the Conversation)" : ""}</div>
        </div>
    );
}

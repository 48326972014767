/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import DatePicker from './DatePicker';
import RoiData from './RoiData';
// import AdminSummary from './AdminSummary';
// import AdminContainer from './AdminContainer';
import ExcelRead from './ExcelRead';
import UseCaseFilter from './UseCaseFilter';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import TicketPerformance from './TicketPerformance';
import RoiView from "./RoiView";
import SelectBox from '../KpiDashhboard/SelectBox';
import StackedBarChart from '../KpiDashhboard/StackedBarChart';
import { Typography } from '@material-ui/core';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    switch: {
        display: "flex",
        marginTop: "40px",
        marginBottom: "20px"
    },
    space: {
        marginLeft: "80px",
        marginTop: "15px"
    },
    summaryHeader: {
        marginTop: '-5px',
        fontSize: '22px',
        marginLeft: '10px'
    },
    channel: {
        marginTop: "32px",
        // marginLeft: "64%",
        fontSize: '20px',
        marginRight: "43%",
        display: "flex",
        marginLeft: '10px'
    },
    filter: {
        marginTop: '-25px',
        marginLeft: '10px'
    },
    stackedBar: {
        marginTop: "30px",
        marginLeft: "10px"
    },
    heading: {
        marginTop: '10px',
        fontSize: '17px',
        marginLeft: '10px'
    }
}))
function Performance(props) {

    const getFormattedDate = (dateparam, check = false) => {
        let today;
        if (check) {
            dateparam.setMonth(dateparam.getMonth() - 3);
            today = dateparam;
        }
        else {
            today = dateparam;
        }
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [filterChannel, setFilterChannel] = useState("phone");
    const [useCaseFilter, setFilterUseCase] = useState("All");
    const [toDate, setToDate] = useState(getFormattedDate(new Date()));
    const [fromDate, setFromDate] = useState(getFormattedDate(new Date(), true));
    const [totalSD, setTotalSD] = useState(0);
    const [filteredSdData, setfilteredSdData] = useState(0);
    const [emailData, setEmailData] = useState();
    const [load, setLoad] = useState(false);
    const [state, setState] = React.useState({
        checkedB: false,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        setDate(today)
    }
    useEffect(() => {
        getDate();
        checkFilter();
        (filterChannel != "phone") &&
            getDataforEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, useCaseFilter])
    const checkFilter = () => {
        if (useCaseFilter !== "All") {
            setState({ checkedB: false })
        }
    }
    //Read from date
    const from = (val) => {
        setFromDate(val);
    }
    //read to date
    const to = (val) => {
        setToDate(val);
    }
    const totalSDCalls = (val) => {
        setTotalSD(val);
    }
    const sdCallsData = (val) => {
        val.shift();
        // console.log(fromDate);
        // console.log(toDate);
        let filteredSum = 0;
        let date = 0;
        let fDate = new Date(fromDate).toISOString().split('T')[0];
        let tDate = new Date(toDate).toISOString().split('T')[0];
        for (var i = 0; i < val.length; i++) {
            date = new Date(val[i].date).toISOString().split('T')[0];
            if (date >= fDate && date <= tDate) {
                if (useCaseFilter === "All") {
                    filteredSum += val[i].count
                } else if (useCaseFilter === "PasswordReset") {
                    filteredSum += val[i].passwordReset
                } else if (useCaseFilter === "TicketStatus") {
                    filteredSum += val[i].ticketStatus
                }
            }
        }
        setfilteredSdData(filteredSum);
    }

    const getDataforEmail = async () => {

        let endpoint = props.emailConfig.chartConfig.totalCalls.endpoint + "/" + fromDate + "/" + toDate;
        const basicAuth = props.emailConfig.authAPI.authHeaders;
        await axios.get(endpoint,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then(res => {
                setEmailData(res.data);
                setLoad(true);
                // console.log(endpoint);
                //   console.log(res);
            })
    }
    //read filter value
    const useCasefilter = (val) => {
        setFilterUseCase(val);
    }
    const filterFunChannel = (val) => {
        setFilterChannel(val);
    }
    return (
        <div style={{ margin: "2%" }}>
            <RoiView config={props.config} />
            <br />
            {/* <AdminSummary date={date} config={props.config} /> */}
            <div className={classes.summaryHeader}>Summary View</div>
            <div className={classes.channel}>Select Channel :
                <div className={classes.filter}>
                    <SelectBox filterFun={filterFunChannel} label="Select Channel" filterData={props.config.filterChannel} />
                </div>
            </div>
            <div className={classes.heading}>Automated Resolution</div>
            <div className={classes.switch}>
                <div>
                    <DatePicker value={fromDate} toDate={toDate} fromDate={fromDate} from={from} to={to} />
                </div>
                <div className={classes.space}>
                    <UseCaseFilter useCaseFilter={useCasefilter} config={filterChannel != "email" ? props.config : props.emailConfig} />
                </div>
            </div>

            {/* <ExcelToJson from={fromDate} to={toDate} useCaseFilter={useCaseFilter} config={props.config} /> */}
            {/* <Chart from={fromDate} to={toDate} useCaseFilter={useCaseFilter} config={props.config} /> */}
            {/* <AdminContainer from={fromDate} to={toDate} config={props.config} /> */}
            {/* <div className={classes.switch}> */}

            {fromDate !== "" && toDate !== "" && useCaseFilter != undefined && <>
                <RoiData totalSD={totalSD} from={fromDate} to={toDate} showExcelData={state.checkedB} useCaseFilter={useCaseFilter} filterSDCalls={filteredSdData} channel={filterChannel} config={filterChannel != "email" ? props.config : props.emailConfig} roiConfig={filterChannel != "email" ? props.config.roi : props.emailConfig.roi} />                {/* <ExcelToJson from={fromDate} to={toDate} useCaseFilter={useCaseFilter} config={props.config} /> */}
                {/* <Chart from={fromDate} to={toDate} useCaseFilter={useCaseFilter} config={props.config} /> */}
                {/* <AdminContainer from={fromDate} to={toDate} config={props.config} /> */}
                {/* <div className={classes.switch}> */}

                {filterChannel != "email" && useCaseFilter === "All" && <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.checkedB}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="View Total SD Calls"
                    />
                </FormGroup>}
                {filterChannel != "email" && <ExcelRead showExcelData={state.checkedB} from={fromDate} to={toDate} totalSDCalls={totalSDCalls} sdCallsData={sdCallsData} useCaseFilter={useCaseFilter} config={props.config} />}
            </>}
            {filterChannel != "email" ? <TicketPerformance config={props.config} date={date} from={fromDate} to={toDate} /> :
                <>
                    {load && useCaseFilter === "StoreTillRestart" &&
                        <div className={classes.stackedBar}>Store Till Restart Summary
                        <StackedBarChart header={props.emailConfig.authAPI.authHeaders} data={emailData} chartConfig={props.emailConfig.chartConfig.totalCalls} />
                        </div>
                    }
                </>
            }
        </div>
    )
}
export default Performance;



/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import stylesConfig from '../../data/stylesConfig.json';
import SelectBox from './SelectBox';
import OrgChartContainer from './OrgChartContainer';
import CurrentDayData from './CurrentDayData';
import Switch from './Switch';
import DetailedReport from './DetailedReport';
// import CurrentDayTicketData from './CurrentDayTicketData';
import ChartTabs from './ChartTabs';
import Container from './Container';
// import UsageDistribution from './AdminDashboard/UsageDistribution';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box paddingLeft="0px" paddingRight="0px">
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export default function SimpleTabs(props) {
    const classes = useStyles();
    const [filter, setFilter] = React.useState(7);
    const [filterChannel, setFilterChannel] = useState("phone");
    const [date, setDate] = useState(null);
    const { value } = props;

    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        setDate(today)
    }

    useEffect(() => {
        getDate();
    }, [props])

    const filterFun = (val) => {
        setFilter(val)
    }

    const filterFunChannel = (val) => {
        setFilterChannel(val);
    }
    //console.log(props.index)
    return (

        <div className={classes.root}>
            {value === 0 || value === 1 ? <div className={value === 0 ? classes.tab1 : classes.tab2}>
                <div className={classes.smartIVR}>
                    {filterChannel !== "email" ? "Smart IVR Performance" : "Smart Email Performance"}
                </div>
                <div className={classes.Dropdown}>
                    {/* <div className={classes.filterLable}> Channel  </div> */}
                    {props.index !== 4 ? <SelectBox filterFun={filterFunChannel} label="Channel" filterData={props.config.filterChannel} /> : ""}
                    {/* <div className={classes.filterLable}> Duration  </div> */}
                    <SelectBox filterFun={filterFun} label="Duration" filterData={props.config.filter} />
                </div>
            </div> : value === 2 ? <div className={value === 0 ? classes.tab1 : classes.tab2}>
                <div className={classes.smartIVR}>Smart IVR Detailed Report </div>
                {/* <div className={classes.DropdownEmail}> */}
                {/* <SelectBox filterFun={filterFunChannel} label="Channel" filterData={props.config.filterChannel} /> */}
                {/* </div> */}
            </div> : ""}
            <TabPanel style={{ marginTop: "-40px" }} value={value} index={0}>
                <div className={classes.container}>
                    <CurrentDayData config={filterChannel !== "email" ? props.config : props.emailConfig} filter={filterChannel} index={props.index} />
                    {/* <CurrentDayTicketData config={filterChannel !== "email" ? props.config : props.emailConfig} /> */}
                    {filterChannel != 'email' && props.index === 1 ?

                        <ChartTabs filterVal={filter} channel={filterChannel} config={filterChannel !== "email" ? props.config : props.emailConfig} />
                        :
                        <Container filterVal={filter} channel={filterChannel} config={filterChannel !== "email" ? props.config : props.emailConfig} index={props.index} />
                    }

                    {/* <Container filterVal={filter} channel={filterChannel} config={filterChannel !== "email" ? props.config : props.emailConfig} /> */}
                    {/* <UsageDistribution /> */}
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className={classes.smartIVR}>Smart IVR Detailed Report </div>
                <div style={{ backgroundColor: "#e4e4e4" }}>
                    <OrgChartContainer config={props.config} filterVal={filter} hierarchy={props.hierarchy} />
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DetailedReport config={filterChannel !== "email" ? props.config : props.emailConfig} date={date} />
            </TabPanel>
            <TabPanel value={value} className={classes.configurationTab} index={3}>
                <Switch config={props.config} />
                {/* <BusinessHours config={props.config} /> */}
            </TabPanel>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        backgroundColor: stylesConfig.basic.appbarColor,
        position: "static",
        width: "100%",
        zIndex: "1",
    },
    filterLable: {
        marginTop: "22px",
        fontSize: "20px"
    },
    Dropdown: {
        marginTop: "-32px",
        // marginLeft: "64%",
        marginLeft: "53%",

        display: "flex",
        alignItems: "right"
    },
    DropdownEmail: {
        marginTop: "-32px",
        marginLeft: "80%",
        display: "flex",
        alignItems: "right"
    },
    container: {
        paddingTop: "35px",
        background: "#E4E4E4",
        minHeight: "100vh"
    },
    box: {
        backgroundColor: stylesConfig.basic.appbarColor,
        boxShadow: "5px 5px 15px #A8A4AD"
    },
    smartIVR: {
        fontSize: "25px",
        margin: "10px 0px 0px 20px"
    },
    tab1: {
        backgroundColor: "#E4E4E4",
        marginTop: "-5px"
    },
    configurationTab: {
        padding: "2%"
    }
}));
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from "@azure/msal-react";

export default function (props) {
    const { accounts, instance } = useMsal();


    const logOut = async () => {
        await instance.logoutRedirect();
    }
    const handleOnIdle = event => {
        logOut();
        // console.log('last active', getLastActiveTime())
    }

    const handleOnActive = event => {
        // console.log('user is active', event)
        // console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
        // console.log('user did something', event)
    }

    const { getRemainingTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    return (
        <>
        </>
    )
}
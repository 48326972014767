/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import StackedAreaChart from './StackedAreaChart';
import LineChart from './LineChart';
import TicketStackedBarChart from './TicketStackedBarChart';
import StackedBarChart from './StackedBarChart';
import stylesConfig from '../../data/stylesConfig.json';
import Card from '@material-ui/core/Card';
import { CircularProgress } from '@material-ui/core';
import HorizontalStackedBar from './HorizontalStackedBar';

const useStyles = makeStyles((theme) => ({
    card1: {
        width: stylesConfig.basic.cardWidth,
        height: stylesConfig.basic.cardHeight,
        borderRadius: 0,
        marginBottom: "10px"
    },
    card2: {
        width: "49.2%",
        height: stylesConfig.basic.cardHeight,
        borderRadius: 0,
        marginBottom: "10px",
        // overflowY: "auto",
        paddingLeft: "5px"
    },
    card3: {
        width: "34.6%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    card4: {
        width: "64.5%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    card5: {
        width: "49.2%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    title: {
        marginTop: "15px",
        marginLeft: "15px",
        fontSize: "20px",
        marginBottom: "20px"
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100% - 120px)"
    }
}))

function TicketRenderChart(props) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);
    const [date, setDate] = useState("");
    const [filter, setFilter] = useState(7);
    // console.log(props.BusinessHoursFilter);

    //format date 
    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '-' + mm + '-' + yyyy;
        setDate(today)
    }


    useEffect(() => {
        setFilter(props.filterVal)
        getDate();
        const timer = setTimeout(() => {
            getData();
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    //API call
    const getData = async () => {

        let endpoint = ""
        props.config.queryParams === "true" ? endpoint = props.config.endpoint + "/" + props.filterVal : endpoint = props.config.endpoint;
        const basicAuth = props.apiHeader;
        await axios.get(endpoint,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then(res => {
                setData(res.data);
                setLoad(true);
            })
    }

    //Check type of the chart and render
    const renderChart = (chart, data) => {
        if (data) {
            switch (chart.chartType) {
                case "StackedBar":
                    return <div><StackedBarChart header={props.apiHeader} data={data} chartConfig={chart} date={date} filter={filter} /></div>
                case "TicketStackedBar":
                    return <div><TicketStackedBarChart header={props.apiHeader} data={data} chartConfig={chart} date={date} filter={filter} businessHour={props.BusinessHoursFilter} /></div>
                case "StackedArea":
                    return <div><StackedAreaChart data={data} chartConfig={chart} date={date} /></div>;
                case "Line":
                    return <div><LineChart header={props.apiHeader} data={data} chartConfig={chart} date={date} filter={filter} /></div>;
                case "HorizontalStackedBar":
                    return <div><HorizontalStackedBar data={data} chartConfig={chart} date={date} /></div>
                default:
                    return <div></div>
            }
        }

    }

    const cardSize = () => {
        switch (props.config.chartType) {
            case "Pie": return classes.card2;
            case "List": return props.channel !== "email" ? classes.card3 : classes.card5;
            case "HorizontalStackedBar": return classes.card4;
            case "StackedArea": return classes.card2;
            case "Line": return classes.card2;
            case "TicketStackedBar": return classes.card2;
            default: return classes.card1
        }
    }
    return (
        <>
            <Card className={cardSize()}>
                <div className={classes.title}>{props.config.title}</div>
                {load ? renderChart(props.config, data) : <div className={classes.loading}><CircularProgress /></div>}
            </Card>
        </>
    )
}

export default TicketRenderChart;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from "react";
import Composed from "../../components/AdminDashboard/Composed";
import Composedchart from "./Composedchart";

export default function Chart(props) {
  const [chartData, setChartData] = useState([]);
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  const [legend, setLegend] = useState("");
  // const [totalSDCalls, setTotalSDCalls] = useState(0);

  useEffect(() => {
    // getChartData(props.config);

    format(props.sdCalls);
    // props.totalSDCalls(totalSDCalls);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);


  // Check the number of days and display
  const getDate = () => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(props.from);
    const secondDate = new Date(props.to);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays < 40) {
      return props.from + "/" + props.to;
    } else {
      let today = new Date(props.to);
      let last40Days = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 40
      );
      var dateObj = new Date(last40Days);
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;
      return newdate + "/" + props.to;
    }
  };

  const overAllData = (tickStatus, passReset) => {
    let resultArr = [];
    tickStatus.sort(function (a, b) {
      return new Date(a.day) - new Date(b.day);
    });

    passReset.sort(function (a, b) {
      return new Date(a.day) - new Date(b.day);
    });

    let resultAsPerDates = getDate();
    resultAsPerDates = resultAsPerDates.split("/");
    let startDate =
      tickStatus[0] && tickStatus[0].day > passReset[0] && passReset[0].day
        ? tickStatus[0] && tickStatus[0].day
        : passReset[0] && passReset[0].day;
    for (
      let d = new Date(startDate), i = 0;
      d <= new Date(resultAsPerDates[1]);
      d.setDate(d.getDate() + 1), i++
    ) {
      let dd = d.toISOString().split("T")[0];
      let tic = tickStatus[i] && tickStatus[i].day;
      let pass = passReset[i] && passReset[i].day;
      if (tic !== undefined) {
        tic = new Date(tic).toISOString().split("T")[0];
      }
      if (pass !== undefined) {
        pass = new Date(pass).toISOString().split("T")[0];
      }
      if (dd === tic && dd === pass) {
        resultArr.push({
          day: dd,
          Totalcount: tickStatus[i].Totalcount + passReset[i].Totalcount,
          successcount: tickStatus[i].successcount + passReset[i].successcount,
        });
      } else if (dd === tic) {
        resultArr.push({
          day: dd,
          Totalcount: tickStatus[i].Totalcount,
          successcount: tickStatus[i].successcount,
        });
      } else if (dd === pass) {
        resultArr.push({
          day: dd,
          Totalcount: passReset[i].Totalcount,
          successcount: passReset[i].successcount,
        });
      }
    }

    return resultArr;
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const findObjectInArray=(arr,obj)=>{
    for(let i=0;i<arr.length;i++)
    {
      if(arr[i].date===obj.day)
      {
        return i;
      }
    }
    return -1;
}
 //Find obj in Excel sheet array
const findObjectInArrayRes=(arr,obj)=>{
  for(let i=0;i<arr.length;i++)
  {
    if(arr[i].date===obj.date)
    {
      return i;
    }
  }
  return -1;
}
  //Sort Dates
  const formatData = (arr) => {
    let res = [];
    res = arr.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    let finalArr = [];
    res.map((i) => {
      return finalArr.push({
        date: i.date.substring(0, i.date.length - 5),
        totalSdCalls: i.totalSdCalls,
        smartIvrCalls: i.smartIvrCalls,
        resolved: i.resolved,
        unresolved: i.unresolved,
      });
    });
    setChartData(finalArr);
  };

  //Combine API data with excel data
  const format = (data) => {
    const formatDate = (value) => {
      let d = new Date(value);
      var day = d.getUTCDate();
      var month = monthNames[d.getUTCMonth()];
      var year = d.getUTCFullYear();
      return month + "-" + day + "-" + year;
    };
    let res=[];
    data.map((i) => {
      let checkDate=new Date(i.date);
      console.log(getDate());
      let fromDate=new Date(getDate().split('/')[0]);
      let toDate=new Date(getDate().split('/')[1]);
      if(checkDate >=fromDate && checkDate <= toDate)
      {
       res.push({
         date: formatDate(i.date),
         totalSdCalls: i.count,
         passwordReset: i.passwordReset,
         ticketStatus: i.ticketStatus,
       });
     }
     });
    let resultArr = [];
    if (props.useCaseFilter === "All") {
      let sum = [];
      setTitle("Password reset and Ticket status");
      let temporaryData=[];
      props.ticketStatus.map((key)=>{
        temporaryData.push({
          date:key.day,
          smartIvrCalls:  +key.Totalcount,
          resolved:  +key.successcount,
          unresolved:  +key.Totalcount - (+key.successcount),
        })
      })
      props.passwordReset.map((key)=>{
        let index=findObjectInArray(temporaryData,key);
        // console.log(index);
        if(index>=0)
        {
            let newObj={
              date:key.day,
              smartIvrCalls: +temporaryData[index].smartIvrCalls + +key.Totalcount,
              resolved: +temporaryData[index].resolved + +key.successcount,
              unresolved: +temporaryData[index].unresolved + (+key.Totalcount -key.successcount),
            }
            temporaryData[index]=newObj;
        }
        else 
        {
             temporaryData.push({
              date:key.day,
              smartIvrCalls:  +key.Totalcount,
              resolved:  +key.successcount,
              unresolved:  +key.Totalcount - (+key.successcount),
             })
        }
      });
      // console.log(temporaryData);
      // console.log(res);
      res.map((key)=>{
        sum.push(key.totalSdCalls);
        let index=findObjectInArrayRes(temporaryData,key);
        if(index>=0)
        {
           resultArr.push({
            totalSdCalls: key.totalSdCalls,
            ...temporaryData[index]
           })
        }
        else 
        {
          resultArr.push({
            totalSDCalls:key.totalSdCalls,
            date:key.date,
            smartIvrCalls:0,
            resolved:0,
            unresolved:0
          })
        }
      })
      props.totalSDCalls(sum.reduce((a, b) => a + b, 0));
      formatData(resultArr);
      setLabel("Total SD calls");
    } else if (props.useCaseFilter === "PasswordReset") {
      let sum = [];
      setTitle("Password Reset");
      props.passwordReset.map((i) => {
        let roiDate = i.day;
        res.map((obj) => {
          let arrDate = obj.day;
          if (roiDate === arrDate) {
            sum.push(obj.passwordReset);
            resultArr.push({
              date: obj.day,
              totalSdCalls: obj.passwordReset,
              smartIvrCalls: i.Totalcount,
              resolved: i.successcount,
              unresolved: +i.Totalcount - +i.successcount,
            });
          }
        });
      });
      console.log(resultArr);
      formatData(resultArr);
      props.totalSDCalls(sum.reduce((a, b) => a + b, 0));
      setLabel("Total password reset SD calls");
      setLegend("Password Reset");
    } else {
      let sum = [];
      setTitle("Ticket Status");
      props.ticketStatus.map((i, j) => {
        let roiDate = i.day;
        res.map((obj) => {
          let arrDate = obj.day;
          if (roiDate === arrDate) {
            sum.push(obj.ticketStatus);
            resultArr.push({
              date: obj.day,
              totalSdCalls: obj.ticketStatus,
              smartIvrCalls: i.Totalcount,
              resolved: i.successcount,
              unresolved: +i.Totalcount - +i.successcount,
            });
          }
        });
      });
      props.totalSDCalls(sum.reduce((a, b) => a + b, 0));
      formatData(resultArr);
      setLabel("Total Ticket Status SD calls");
      setLegend("Ticket Status");
    }
  };
  return (
    <div>
      {chartData.length !== 0 ? (
        <Composed
          label={label}
          legend={legend}
          showExcelData={props.showExcelData}
          config={props.config}
          data={chartData}
        />
      ) : props.useCaseFilter === "TicketStatus" ? (
        <Composedchart
          message={"Total SD calls data is available till Jan 3rd."}
          legend={"Ticket Status"}
          data={props.ticketStatus}
        />
      ) : props.useCaseFilter === "PasswordReset" ? (
        <Composedchart
          data={props.passwordReset}
          message={"Total SD calls data is available till Jan 3rd"}
          legend={"Password Reset"}
        />
      ) : (
        <Composedchart
          message={"Total SD calls data is available till Jan 3rd"}
          data={overAllData(props.ticketStatus, props.passwordReset)}
          legend={""}
        />
      )}
    </div>
  );
}

/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme, props) => ({
    card: {
        width: "19.3%",
        height: "91px",
        borderRadius: 0,
        marginBottom: "10px",
        display: "flex",
        // borderRadius: "3px"
    },
    card1: {
        width: "24%",
        height: "91px",
        borderRadius: 0,
        marginBottom: "10px",
        display: "flex",
        // borderRadius: "3px"
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "2%",
        marginLeft: "2%",
        justifyContent: "space-between"
    },
    align: {
        display: "flex",
        alignItems: "center",
        marginLeft: "25%"
    },
    title: {
        fontSize: "14px",
        margin: "-9px 11px 0px 10px",
        color: "#73716a"
    },
    data: {
        fontSize: "30px",
        margin: "8px 0px 0px 20px",
        // color: "#0077b6",
        fontWeight: "bold"
    },
    icon: {
        margin: "15px 0px 0px 12px",
        borderRadius: "50%",
        //background: "#DFF8F9",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    phoneIcon: {
        //fill: "#0077b6",
        fontSize: "30px"
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "27px 0px 0px 40px"
    },
    status: {
        margin: "0px 0px 5px 25px",
        fontSize: "22px",
    },
    resolution: {
        margin: "30px 0px 5px 25px",
        fontSize: "17px",
    }
}))

function CurrentDayData(props) {
    const classes = useStyles();
    const [formatedData, setFormatedData] = useState([]);

    // let result = [];
    useEffect(() => {
        getData();
        return () => {
            setFormatedData([]);
        }
    }, [props])

    //To get the count of number of calls
    const getCount = (title) => {
        return formatedData[title];
    }

    //To render icons
    const getIcon = (title) => {
        switch (title) {
            case "Total Calls": return <PhoneCallbackIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Resolved Calls": return <PhoneInTalkIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Handover Calls": return <PeopleOutlineIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Calls Disconnected by User": return <PhoneMissedIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Unresolved calls": return <PhoneMissedIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Total Tickets": return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Total  Requested Stores": return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Confirmation From Stores": return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Stores Notify Via Mails": return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            case "Stores In Queue": return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />
            default: return <MailIcon style={{ fill: `${props.config.dailyStatusIcons.icon}` }} className={classes.phoneIcon} />;
        }
    }

    // const getTooltip = 

    //API call
    const getData = async () => {
        let data = {};
        const basicAuth = props.config.authAPI.authHeaders;
        await axios.get(`${props.config.dailyOverview.endpoint}`,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then(res => {
                data = res.data[0];
                //  console.log(res); 
            })
            .catch(e => console.log(e))
        setFormatedData(data)


    }

    return (
        <>
            <div className={classes.status}>Today's Status</div>
            <div className={classes.resolution}>Automated Resolution</div>
            <div className={classes.container}>
                {Object.keys(props.config.dailyOverview.keyValue).map((i, index) => {
                    return (
                        <Tooltip key={index} title={props.config.dailyOverview.tooltip[i]}>
                            <Card className={props.index == 4 ? classes.card1 : classes.card}>
                                <div className={classes.icon} style={{ background: `${props.config.dailyStatusIcons.background}` }}>{getIcon(props.config.dailyOverview.keyValue[i])}</div>
                                <div>
                                    <div className={classes.data}>{formatedData.length !== 0 ? getCount(i) : <div className={classes.loading}><CircularProgress size={25} /></div>}</div>
                                    <div className={classes.title}>{formatedData.length !== 0 && props.config.dailyOverview.keyValue[i]}</div>
                                </div>
                            </Card>
                        </Tooltip>
                    )
                })}
            </div>
        </>
    )
}

export default CurrentDayData;
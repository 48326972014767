/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const useStyles = makeStyles((theme) => ({
    container: {
        padding: "0px 20px 20px 20px",
        marginTop: "30px"
    },
    heading: {
        fontSize: "25px",
        marginBottom: "30px"
    },
    Button: {
        width: "190px",
        height: "30px",
        marginTop: "17px",
        textTransform: "inherit",
        fontSize: "15px",
    },
    excel: {
        margin: "30px 0 0 90px "
    },
    CircularProgress: {
        marginLeft: "20px"
    }
}))

function DetailedReport(props) {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(props.date);
    const [toDate, setToDate] = useState(props.date);
    //const [currentDate, setCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [download, setDownload] = useState([]);
    const [isDownload, setIsDownload] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [fromStr, setFromStr] = useState("");
    const [toStr, setToStr] = useState("");
    // const downloadEle = useRef(null);
    // const ExcelFile = ReactExport.ExcelFile;
    // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const exportToCSV = (apiData, filename) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        console.log(apiData);
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { OverallData: ws }, SheetNames: ["OverallData"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, filename + fileExtension);
    };

    //Excel headings
    // const headers = [
    //     { key: "useCaseType", label: "SmartIVR Use Case" },
    //     { key: "callStatus", label: "Status of the Session" },
    //     { key: "reasonOfFailure", label: "Reason of Failure" },
    //     { key: "callDuration", label: "AHT of the Session(in minutes)" },
    //     { key: "agentTransfer", label: "Agent Transfer from SmartIVR" },
    //     { key: "descRadius", label: "Disconnected by User/Forwarded To Agent" },
    //     { key: "sessionId", label: "Conversation ID" },
    //     { key: "sessionDate", label: "Date of Call" },
    //     { key: "startTime", label: "Start Time" },
    //     { key: "endTime", label: "End Time" },
    //     { key: "failurePoint", label: "Point of Failure" },
    //     { key: "ApplicationName", label: "Application Name" },
    //     { key: "conversation", label: "Conversation with Smart IVR" },
    //     { key: "eventTypeRadius", label: "Event" },
    // ]

    const formatDetailedReport = (data) => {
        let updatedData = data.map(ele => ({
            "SmartIVR Use Case": ele.useCaseType,
            "Status of the Session": ele.callStatus,
            "Reason of Failure": ele.reasonOfFailure,
            "AHT of the Session(in minutes)": ele.callDuration,
            "Agent Transfer from SmartIVR": ele.agentTransfer,
            "Disconnected by User/Forwarded To Agent": ele.descRadius,
            "Conversation ID": ele.sessionId,
            "Date of Call": ele.sessionDate,
            "Start Time": ele.startTime,
            "End Time": ele.endTime,
            "Point of Failure": ele.failurePoint,
            "Application Name": ele.ApplicationName,
            "Conversation with Smart IVR": ele.conversation,
            "Event": ele.eventTypeRadius


        }))
        return updatedData;
    }

    //To download CSV
    const getDownload = async () => {
        setDownload([]);
        setIsDownload(false);
        setDisableBtn(true);
        const basicAuth = props.config.authAPI.authHeaders;
        await axios.get(`${props.config.dailyReport.endpoint}/${fromDate}/${toDate}`,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then((res) => {
                if (res.data.length > 0) {
                    res.data.sort(function (a, b) {
                        return new Date(a.day) - new Date(b.day);
                    });
                    setDownload(res.data);
                    setIsDownload(true);
                    setDisableBtn(false);
                    let updatedData = formatDetailedReport(res.data);
                    exportToCSV(updatedData, `OverallData_${fromStr}-${toStr}`)
                } else {
                    setIsDownload(false);
                }
                setDisableBtn(false);
                return res.data;
            })
    }

    useEffect(() => {
        getDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.date])

    //Sort date
    const getDate = () => {
        //setCurrentDate(props.date);

        var date = new Date();
        var min = new Date(props.date);
        var d = String(min.getDate()).padStart(2, '0');
        var m = String(min.getMonth() + 1).padStart(2, '0');
        var y = min.getFullYear() - 1;
        date = y + '-' + m + '-' + d;
        setMinDate(date);
    }

    const handleToEvent = (value) => {
        setToDate(value);
        let to = new Date(value);
        let toMonth = new Date(value).getMonth() + 1;
        setToStr(to.getFullYear() + "." + toMonth + "." + to.getDate());
    }
    const handleFromEvent = (value) => {
        setFromDate(value);
        let from = new Date(value);
        let fromMonth = new Date(value).getMonth() + 1;
        setFromStr(from.getFullYear() + "." + fromMonth + "." + from.getDate());
    }
    return (
        <div className={classes.container}>
            {/* {console.log(`${fromStr}/${toStr}`)} */}
            {/* <div className={classes.heading}>Detailed Report</div> */}
            <TextField
                id="date"
                label="From"
                type="date"
                defaultValue={props.date}
                onChange={(event) => { handleFromEvent(event.target.value) }}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ min: minDate, max: props.date }}
            />
            {fromDate !== "" && <TextField
                id="date"
                label="To"
                type="date"
                style={{ marginLeft: "100px" }}
                defaultValue={props.date}
                onChange={(event) => { handleToEvent(event.target.value) }}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ min: fromDate, max: props.date }}
            />}
            <div className={classes.excel}>
                <Button size="small" disabled={disableBtn} variant="contained" color="primary" onClick={getDownload}>Download raw data</Button>
                {disableBtn ? <CircularProgress size={20} className={classes.CircularProgress} /> : ""}
            </div>
        </div>
    )
}

export default DetailedReport;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextCustomEdit from './TextCustomEdit';
import axios from 'axios';
import { Tooltip } from '@material-ui/core';
import ChooseLanguage from './ChooseLanguage';
// import { SettingsVoice } from '@material-ui/icons';
//import Blob from 'blob';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: "30px 3% 30px 3%",
    },
    root1: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        width: 1200,
        boxShadow: "rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 0.5px;"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        overflowY: "auto",
    },
    divider: {
        height: 28,
        margin: 4,
    },
    validate: {
        color: "red",
        marginTop: "5px"
    },
    options: {
        display: "flex"
    },
    // text: {
    //     display: "flex",
    //     justifyContent: "space-between"
    // },
    icons: {
        width: "120px"
    },
    button: {
        margin: "10px 10px 0 0",
        backgroundColor: "#6B2B82",
        "&:hover": {
            backgroundColor: "#6B2B82"
        },
        color: "white",
        //width: "100px"
    },
    button1: {
        margin: "10px 10px 0 0",
        backgroundColor: "#DC143C",
        "&:hover": {
            backgroundColor: "#DC143C"
        },
        color: "white",
    },
    endContainer: {
        display: "flex",
        width: "800px",
        marginTop: "20px"
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    iconColor: {
        fill: "#6B2B82"
    }
}));


function AudioGenerator(props) {
    const classes = useStyles();
    const [voice, setVoice] = useState([]);
    const [defaultTags, setDefaultTags] = useState({ Voice: "", Break: "none", Volume: "default", Rate: "default", Pitch: "default", Interpret: "", Format: "" });
    const [text, setText] = useState("");
    const [validate, setValidate] = useState(false);
    const [list, setList] = useState([]);
    // const [speak, setSpeak] = useState({});
    // const [audioUrl, setAudioUrl] = useState("");
    const [audioArray, setAudioArray] = useState([]);
    // const [localstorage, setLocalStorage] = useState({});
    const [showOptions, setShowOptions] = useState(false);
    const [error, setError] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [metadata, setMetadata] = useState({ Platform: "", Language: "", Voice: "" });
    const [errorMessage, setErrorMessage] = useState("");

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    //Alert message open event
    // const handleClick = () => {
    //     setOpen(true);
    // };

    //Alert modal close event
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    //Callback from dropdown to get dropdown values
    const audioGeneratorCallback = (metadata, voice) => {
        setMetadata(metadata);
        setVoice(voice);
        setDefaultTags({ Voice: voice[0].value, Break: "none", Volume: "default", Rate: "default", Pitch: "default", Interpret: "", Format: "" })
        setShowOptions(false);
    }

    useEffect(() => {
        if ((metadata.Language === "" && metadata.Platform === "")) {
            setShowOptions(true);
        }
    }, [props])

    //Add edited list item
    const handleAdd = () => {
        if (text !== "") {
            setList(l => [...l, { text: text, tags: defaultTags, ssml: "" }]);
            setText("");
            setValidate(false);
        } else
            setValidate(true);
    }

    //Delete list item
    const handleDelete = (item) => {
        setList(i => i.filter((a, index) => index !== item))
    }

    //Convert buffer data to array buffer
    const toArrayBuffer = (buf) => {
        var ab = new ArrayBuffer(buf.length);
        var view = new Uint8Array(ab);
        for (var i = 0; i < buf.length; ++i) {
            view[i] = buf[i];
        }
        return ab;
    }

    //Play audio on every list item
    const handlePlay = (item) => {
        let ssmlVar = ssmlgenerator(item);
        let finalSsml = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${metadata.Language}">
      
            ${ssmlVar}
       
        </speak>`
        let formData = new FormData();
        formData.append("ssml", finalSsml);
        formData.append("audioformat", "wav");

        axios.post(props.config.audioTool.endpoint, formData, {
            auth: {
                username: "admin",
                password: props.config.authAPI.authHeaders
            }
        })
            .then((res) => {
                let audio = new Audio();
                const blob = new Blob([toArrayBuffer(res.data.filestream.data)], { type: "audio/wav" });
                audio.src = window.URL.createObjectURL(blob);
                audio.load();
                audio.play();
            }, (error) => {
                if (error.response.status && error.response.status === 429) {
                    setOpen(true);
                    setError(error.response.status);
                    setErrorMessage("Limit exceeded!")
                } else {
                    setOpen(true);
                    setErrorMessage("Something went wrong, Please try again in some time.")
                }
            })
    }

    //Mentod to edit
    const editData = (edit, index, editedValue) => {
        list[index].text = editedValue;
    }

    //Method to handle submit
    const handleSubmit = (index, tags) => {
        list[index].tags = tags;
    }

    //Add Tags in SSML
    const ssmlgenerator = (item) => {
        const { Voice, Pitch, Rate, Volume, Interpret, Format, Break } = list[item].tags;
        const Text = list[item].text;

        let resultSSML = '';

        // Assign to default when none of the attributes are selected
        if (Pitch === 'default' && Rate === 'default' && Volume === 'default' && Interpret === '' && Break === "none") {
            resultSSML = Text;
        }

        // Interpret
        if (Interpret && Interpret !== '') {
            resultSSML = `<say-as interpret-as="${Interpret}" `;
            resultSSML += Format !== '' ? `format="${Format}"` : '';
            resultSSML += `>${Text}</say-as>`;
        } else {
            resultSSML = Text;
        }

        // Prosody
        if (!(Pitch === 'default' && Rate === 'default' && Volume === 'default')) {
            let prosody = `<prosody `;
            prosody += Pitch !== 'default' ? `pitch="${Pitch}" ` : '';
            prosody += Rate !== 'default' ? `rate="${Rate}" ` : '';
            prosody += Volume !== 'default' ? `volume="${Volume}" ` : '';
            prosody += `>${resultSSML}</prosody>`;
            resultSSML = prosody;
        }

        // Break
        if (Break !== "none") {
            resultSSML += `<break strength="${Break}" />`;
        }

        return `<voice name="${Voice}">
        ${resultSSML}
    </voice>`
    }

    //Method to generate SSML tags
    const generateSSML = () => {
        list.map((i, index) => {
            let ssml = ssmlgenerator(index);
            i.ssml = ssml;
        })
        let finalSsml = ""
        finalSsml = list.map(i => i.ssml).join("");
        let ssml = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${metadata.Language}">
       
        ${finalSsml}
      
        </speak>`
        return ssml;
    }

    //Crop functionality
    const selectedText = (index, croppedText, startIndex, endIndex) => {
        if (croppedText !== "") {
            let txt = list[index].text.replace(list[index].text.substring(startIndex, endIndex), "");
            list[index].text = txt;
            let obj = { text: croppedText, tags: defaultTags, ssml: "" };
            setList(l => {
                l.splice(index + 1, 0, obj);
                return [...l];
            });
        }
    }

    //API call on submit
    const getAudio = () => {
        setAudioArray("");
        let ssml = generateSSML();
        let formData = new FormData();
        formData.append("ssml", ssml);
        formData.append("audioformat", "wav");
        axios.post(props.config.audioTool.endpoint, formData, {
            // headers: {
            //     'Authorization': props.config.authAPI.authHeaders,
            //     'Content-Type': 'application/json'
            // }
            auth: {
                username: "admin",
                password: props.config.authAPI.authHeaders
            }
        })
            .then((res) => {
                // setAudioUrl(res.data.responseurl);
                setAudioArray(res.data.filestream.data);
            }, (error) => {
                if (error.response.status && error.response.status === 429) {
                    setOpen(true);
                    setError(error.response.status);
                    setErrorMessage("Limit exceeded!")
                } else {
                    setOpen(true);
                    setErrorMessage("Something went wrong, Please try again in some time.")
                }
            })
    }

    //Play audio on submit
    const Play = (arr) => {
        let audio = new Audio();
        const blob = new Blob([toArrayBuffer(arr)], { type: "audio/wav" });
        audio.src = window.URL.createObjectURL(blob);
        audio.load();
        audio.play();
    }

    //Download audio file on submit
    const download = (arr) => {

        const url = window.URL.createObjectURL(new Blob([toArrayBuffer(arr)], { type: "audio/wav" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.wav');
        document.body.appendChild(link);
        link.click();

    }

    return (
        <div>
            {showOptions ? <ChooseLanguage callback={audioGeneratorCallback} /> : <div className={classes.container}>
                <Paper className={classes.root1}>
                    <InputBase
                        className={classes.input}
                        placeholder="Add text here"
                        multiline
                        rows={8}
                        value={text}
                        onChange={event => { setText(event.target.value) }}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <Tooltip title="Add content">
                        <IconButton color="primary" onClick={handleAdd}>
                            <AddIcon className={classes.iconColor} />
                        </IconButton></Tooltip>
                </Paper>
                {validate ? <div className={classes.validate}>This field cannot be empty</div> : ""}
                {list.length !== 0 && list.map((i, index) => {
                    return <>
                        {defaultTags.Voice !== "" && voice.length !== 0 && <TextCustomEdit selectedText={selectedText} editData={editData} tags={i.tags} voice={voice} submit={handleSubmit} List={list} play={handlePlay} index={index} delete={handleDelete} text={i.text} />}
                        <Divider />
                    </>
                })}
                {list.length > 0 && <div className={classes.endContainer}>
                    <div><Button className={classes.button} variant="contained" onClick={() => { getAudio() }}>
                        Submit
                    </Button></div>

                    {audioArray.length !== 0 && list.length !== 0 &&
                        <Button className={classes.button} variant="contained" onClick={() => { Play(audioArray) }}>
                            Play
                        </Button>}
                    {audioArray.length !== 0 && list.length !== 0 &&
                        <Button className={classes.button} onClick={() => { download(audioArray) }} >
                            Download
                        </Button>}
                    <div><Button className={classes.button1} variant="contained" onClick={() => { return (setList([]), setAudioArray([])) }}>
                        Reset
                    </Button></div>
                </div>}
                <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>}
        </div>
    )
}

export default AudioGenerator;
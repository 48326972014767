/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useEffect, useState } from 'react';
import {
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line
} from 'recharts';
import stylesConfig from '../../data/stylesConfig.json';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//     notFound: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         marginTop: "120px",
//         fontSize: "20px",
//         color: "#0077b6"
//     }
// }))

//const dummyData = [{ month: "Nov-2020" }, { month: "Mar-2001" }, { month: "Feb-2000" }, { month: "Oct-2001" }]

function Stackedareachart(props) {
    // const classes = useStyles();
    const [Data, setData] = useState([]);

    useEffect(() => {
        let data = [];
        data = props.data.sort(function (a, b) {
            return new Date(a.period) - new Date(b.period);
        });

        setData(data);

    }, [props])

    //Set chart colors
    const fillColor = (index) => {
        let arr = [];
        arr = props.chartConfig.color.map(i => i)
        return arr[index]
    }
    const formatXAxis = (val) => {
        val=val.toString();
        return val.split("-", 2).join("-")
    }
    const renderColorfulLegendText = (value,entry) => {
        return <span style={{color:'black'}}>{value}</span>;
      };
    return (
        <>
            <LineChart
                width={stylesConfig.useCase.chartWidth}
                height={stylesConfig.useCase.chartHeight}
                data={Data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={{ fontSize: "13px" }} height={45} tickFormatter={formatXAxis} dataKey={props.chartConfig.xaxis} />
                <YAxis tick={{ fontSize: "13px" }} label={{ value: 'In Minutes', angle: -90, fontSize: "12px", dy: -10, position: "insideLeft" }} />
                <Tooltip />
                <Legend formatter={renderColorfulLegendText} />
                {props.chartConfig.yaxis.map((i, index) => {
                    return <Line type="monotone" key={index} dataKey={i} stackId="1" stroke={fillColor(index)} fill={fillColor(index)} />
                })}
            </LineChart>
        </>
    );
}


export default Stackedareachart;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: "0 3px 0 5px",
        borderRadius: "3px"
    },
}));

export default function SimpleSelect(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const { selected, title } = props;


    //Update filter value on change
    const handleChange = (event) => {
        setAge(event.target.value);
        selected({ [title]: event.target.value })
    };

    return (
        <div title={props.tooltip}>
            <FormControl className={classes.formControl}>
                <Select
                    value={props.currentValue[title] ? props.currentValue[title] : ""}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ backgroundColor: props.backgroundcolor, color: props.color, fontSize: props.fontsize, }}
                >
                    <MenuItem value="" disabled>
                        {title}
                    </MenuItem>
                    {props.value.map(i => {
                        return <MenuItem value={i.value}>{i.name}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{title}</FormHelperText>
            </FormControl>
        </div>
    );
}

/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // container: {
    //     margin: "30px 0px 0px 50px"
    // },
    font: {
        fontSize: "25px"
    }
}))

export default function SwitchesSize(props) {
    const classes = useStyles();
    const [checked, setChecked] = useState(null);
    var toggle = null;
    const basicAuth = props.config.authAPI.authHeaders;
    useEffect(() => {
        axios.get(`${props.config.switch.currentStatusEndpoint}`,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then((res) => {
                setChecked(res.data[0].status)
            })
        // setChecked(false);
    }, [props])

    //API call on toggle
    const toggleChecked = () => {
        setChecked((prev) => !prev);
        toggle = !toggle;
        if (checked) {
            axios.get(`${props.config.switch.switchEndpoint}/0`, {
                auth: {
                    username: "admin",
                    password: basicAuth
                }
            })
                .then(res => res.data)
        } else {
            axios.get(`${props.config.switch.switchEndpoint}/1`, {
                auth: {
                    username: "admin",
                    password: basicAuth
                }
            })
                .then(res => res.data)
        }
    };

    return (
        <>
            {checked !== null && <FormGroup className={classes.container}>
                <div className={classes.font}>Smart IVR Configuration</div>
                <FormControlLabel
                    control={<Switch color="primary" checked={checked} onChange={toggleChecked} />}
                    label={checked ? "On" : "Off"}
                />
            </FormGroup>}
        </>
    );
}
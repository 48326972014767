/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import stylesConfig from '../../data/stylesConfig.json';
import { Toolbar } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Header from './Header';
import AdminTabs from '../AdminDashboard/AdminTabs';

// import { useMsal } from "@azure/msal-react";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box paddingLeft="0px" paddingRight="0px">
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function (props) {
    const { clientFun, val } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(val);
    // const { instance } = useMsal();

    let options = [
        'Select',
        'KPI dashboard',
        'Admin dashboard',
        'Agent dashboard',
        'Kiosk Dashboard'
    ];

    // if (role.length > 1) {
    //     options = [...options, 'Agent dashboard']
    // }


    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        clientFun(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    // const logOut = async () => {
    //     await instance.logoutRedirect();
    // }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Box className={classes.box} display="flex">
                    <Toolbar>   <img src={props.config.logo.logo} alt="logo.svg" width={props.config.logo.width} height={props.config.logo.height} /></Toolbar>
                    <Tabs value={value} className={classes.tabs} onChange={handleChange} aria-label="simple tabs example"
                        TabIndicatorProps={{
                            style: {
                                background: "#0289B9",
                                height: "0px"
                            }
                        }}>

                        {props.config.tabs.map((i, index) => {
                            return <Tab className={classes.tabData} label={i} {...a11yProps(index)} />
                        })}

                    </Tabs>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClickListItem}
                        color="inherit"
                    >
                        <AccountCircle style={{ color: "black" }} />
                    </IconButton>
                    {/* <IconButton
                        onClick={logOut}
                        color="inherit"
                        className={classes.button}
                    >
                        <ExitToAppIcon color="secondary" onClick={logOut} />
                    </IconButton> */}
                    <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {options.map((option, index) => (
                            <MenuItem
                                key={option}
                                disabled={index === 0}
                                selected={index === selectedIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </AppBar>

            {selectedIndex === 1 || selectedIndex === 4 ? <Header config={props.config} hierarchy={props.hierarchy} value={value} emailConfig={props.emailConfig} index={selectedIndex} /> :
                selectedIndex === 2 ? <AdminTabs config={props.config} emailConfig={props.emailConfig} value={value} /> : ""}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        backgroundColor: stylesConfig.basic.appbarColor,
        position: "static",
        width: "100%",
        zIndex: "1",
    },
    tabData: {
        textTransform: "inherit",
        fontSize: "15px",
        minWidth: "50px",
        color: "black",
        marginTop: "8px"
    },
    Dropdown: {
        marginTop: "-52px",
        marginLeft: "81%",
    },
    container: {
        paddingTop: "35px",
        background: "#E4E4E4",
        minHeight: "100vh"
    },
    heading: {
        fontSize: "16px",
        marginLeft: "78%",
        marginTop: "-20px"
    },
    box: {
        backgroundColor: stylesConfig.basic.appbarColor,
        boxShadow: "5px 5px 15px #A8A4AD"
    },
    smartIVR: {
        fontSize: "25px",
        margin: "10px 0px 0px 20px"
    },
    tab1: {
        backgroundColor: "#E4E4E4",
        marginTop: "-5px"
    },
    Button: {
        width: "190px",
        height: "30px",
        marginTop: "17px",
        // backgroundColor: "#00b4d8",
        color: "#00b4d8",
        textTransform: "inherit",
        fontSize: "15px"
    },
    configurationTab: {
        padding: "2%"
    }
}));
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

const chart = {
    marginTop: "-90px",
    marginLeft: "100px"
}

const font = {
    fontSize: "12px",
    fontWeight: "bold"
}



//To get the pie chart shape



export default function Piecharts(props) {
    const [data, setData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    let labelKeyName = props.chartConfig.labelKeyName;

    const COLORS = props.chartConfig.color;

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload, percent, value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';


        return (
            <g>
                {/* {console.log(props.chartConfig.labelKeyName[payload.name], "in here")} */}
                <text style={{ fontSize: "10px", fontWeight: "bold" }} x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{labelKeyName[payload.name]}</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text style={font} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
                <text style={font} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {percent === 1 ? `${(percent * 100)}%` : `${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    const formatData = () => {
        let arr = []
        arr = props.chartConfig.yaxis.map(e => {
            let acc = 0;
            props.data.map((d) => {
                return acc = +acc + +d[e]
            })
            return { name: e, value: acc }
        })
        setData(arr);
    }

    useEffect(() => {
        formatData();
    }, [props])

    const onPieEnter = (data, index) => {
        setActiveIndex(index)
    };

    return (
        <PieChart style={chart} width={450} height={400}>
            <Pie
                data={data}
                cx={200}
                cy={200}
                innerRadius={75}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                onMouseEnter={onPieEnter}
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>

        </PieChart>
    );
}


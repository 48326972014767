/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Area, Legend, CartesianGrid, Bar, Line } from 'recharts';
import stylesConfig from '../../data/stylesConfig.json';
import { makeStyles } from '@material-ui/core/styles';
// import RoiData from '../../data/RoiData.json';


const useStyles = makeStyles((theme) => ({
    download: {
        float: "right",
        margin: "-50px 40px 0px 0px"
    },
    tooltip: {
        backgroundColor: "white",
        fontSize: "12px",
        padding: "10px"
    },
    title: {
        fontSize: "12px",
        color: "grey",
        margin: "15px"

    }
}))

export default function App(props) {
    const classes = useStyles();

    const [Data, setData] = useState([]);
    useEffect(() => {
        let data = [];
        data = props.data.sort(function (a, b) {
            return new Date(a.day) - new Date(b.day);
        });
        let chartData = [];
        data.map(i => {
            return chartData.push({
                day: i.day.substring(0, i.day.length - 5),
                Totalcount: i.Totalcount,
                successcount: i.successcount,
                failedCount: +i.Totalcount - +i.successcount
            })
        })
        setData(chartData);
    }, [props])

    const labelKeyName = {
        "Totalcount": "Smart IVR Calls",
        "successcount": "Resolved Calls",
        "failedCount": "Unresolved Calls"
    }

    //Add customised tooltips
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className={classes.tooltip}>
                    <div>{label}</div>
                    {payload?.map(e => {
                        return <div className="label">{`${labelKeyName[e.name] + ":" + e.value}`}</div>
                    })}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="App">
            <div className={classes.title}>{props.message}</div>
            {Data.length !== 0 && <ComposedChart
                width={stylesConfig.barAndLine.chartWidth}
                height={stylesConfig.barAndLine.chartHeight}
                data={Data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis tick={{ fontSize: "13px" }} dataKey="day" />
                <YAxis tick={{ fontSize: "13px" }} />
                <Tooltip content={<CustomTooltip />} />
                {/* 
                <Legend height={14} payload={
                    [
                        { id: 'failcount', value: 'Smart IVR Calls', type: 'square', color: "#ff7300" },
                        { id: 'successcount', value: 'Resolved calls', type: 'square', color: "red" },

                    ]
                } /> */}

                <Legend height={14} payload={
                    [
                        { id: 'Totalcount', value: `Smart IVR ${props.legend} calls`, type: 'line', color: "#65C0BA" },
                        { id: 'successcount', value: `Resolved ${props.legend} calls`, type: 'square', color: "#216583" },
                        { id: 'failedCount', value: `Unresolved ${props.legend} calls`, type: 'square', color: "#F76262" },
                    ]
                } />

                {/* <Area type="monotone" dataKey="totalSdCalls" fill="#8884d8" stroke="#8884d8" /> */}
                <Line type="monotone" dataKey="Totalcount" stroke="#65C0BA" />
                <Bar dataKey="failedCount" key="2" stackId="a" barSize={20} fill="#F76262" />
                <Bar dataKey="successcount" key="1" stackId="a" barSize={20} fill="#216583" />
                {/* <Bar dataKey={props.chartConfig.yaxisLine} barSize={20} fill="pink" /> */}
            </ComposedChart>}
        </div>
    );
}
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { makeStyles } from '@material-ui/core/styles';
// import Tabs from './components/Tabs';
// import dixonConfig from './data/config.json';
// import sohoConfig from './data/sohoConfig.json';
// import AdminTabs from './components/AdminDashboard/AdminTabs';
// import dixonAdminConfig from './data/DixonAdminConfig.json';
// import HierarchyDixon from './data/HierarchicalChartData.json';
// import HierarchySoho from './data/HierarchyChartSoho.json';
// import { Switch, Route, withRouter } from "react-router-dom";
// import wamConfig from './data/WamConfig.json';
// import sohoAdminConfig from './data/sohoAdminConfig.json';
// import wamAdminConfig from './data/WamAdminConfig.json';
// import { UnauthenticatedTemplate, useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import Agent from '../src/components/AgentDashboard/Agent';
// import wamAgentConfig from '../src/data/WamAgent.json';
// import AutoTimeout from './components/AutoTimeout';

// function ErrorComponent({ error }) {
//   return <p>An Error Occurred: {error}</p>;
// }

// function LoadingComponent() {
//   return <p>Authentication in progress...</p>;
// }

// function App(props) {
//   const [clientConfig, setClientConfig] = React.useState(1);
//   const [roles, setRoles] = useState([]);
//   const { accounts, inProgress } = useMsal();

//   const authRequest = {
//     scopes: ["openid", "profile"]
//   };

//   useEffect(() => {
//     getRoles();
//     const currLocation = window.location.href;
//     if (currLocation.indexOf('/admin') > -1) {
//       setClientConfig(2);
//     }
//   }, [accounts, props])

//   const getRoles = () => {
//     if (accounts.length > 0) {
//       const roles = accounts[0].idTokenClaims.roles;
//       setRoles(roles ?? []);
//     }
//     // setRoles(['Admin']);
//   }

//   const checkAccess = (role) => {
//     return roles.includes(role);
//   }

//   const clientFun = (val) => {
//     setClientConfig(val);
//     props.history.push(getRouteFragment(val));
//   }

//   const getRouteFragment = (val) => {
//     switch (val) {
//       case 1:
//         return "/"
//       case 2:
//         return "/admin"
//       case 3:
//         return "/agent"
//     }
//   }


//   const renderConfig = (val) => {
//     switch (val) {
//       case 1:
//         return <Tabs role={roles} clientFun={clientFun} val={val} config={wamConfig} hierarchy={HierarchyDixon} />
//       case 2:
//         return <Tabs role={roles} clientFun={clientFun} val={val} config={wamAdminConfig} />
//       case 3:
//         return checkAccess('Agent') && <Agent config={wamAgentConfig} />
//     }
//   }


//   return (
//     <>
//       <MsalAuthenticationTemplate
//         interactionType={InteractionType.Redirect}
//         authenticationRequest={authRequest}
//         errorComponent={ErrorComponent}
//         loadingComponent={LoadingComponent}
//       >
//         <AutoTimeout />
//         {inProgress === "none" && roles.length > 0 ?
//           <> {checkAccess('Admin') && <div className="App">
//             <Switch>
//               <Route path="/" component={() => renderConfig(clientConfig)} exact />
//               <Route path="/admin" component={() => renderConfig(clientConfig)} />
//               {checkAccess('Agent') && <Route path="/agent" component={() => renderConfig(clientConfig)} />}
//             </Switch>
//           </div>}
//             {checkAccess('Agent') && roles.length === 1 && <Agent />} </> : <div>You do not have permission, please contact admin</div>}
//       </MsalAuthenticationTemplate>
//       <UnauthenticatedTemplate>
//         <p>You are not authorised</p>
//       </UnauthenticatedTemplate>
//     </>
//   );
// }

// export default withRouter(App);

// /* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
// /* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { makeStyles } from '@material-ui/core/styles';
import Tabs from './components/KpiDashhboard/Tabs';
import dixonConfig from './data/config.json';
import dixonAdminConfig from './data/DixonAdminConfig.json';
import dixonAdminEmailConfig from './data/DixonAdminEmailConfig.json'
import HierarchyDixon from './data/HierarchicalChartData.json';
import dixonEmail from './data/DixonEmail.json';
import { Switch, Route, withRouter } from "react-router-dom";
import AgentScreen from './components/AgentScreen/AgentScreen'
import { UnauthenticatedTemplate, useMsal, MsalAuthenticationTemplate, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import AutoTimeout from './components/KpiDashhboard/AutoTimeout'
import dixonKioskConfig from './data/kioskConfig.json'
import HierarchyKiosk from './data/HierarchialChartDataKiosk.json'
// import sohoConfig from './data/sohoConfig.json';
// import AdminTabs from './components/AdminDashboard/AdminTabs';
// import HierarchySoho from './data/HierarchyChartSoho.json';
// import wamConfig from './data/WamConfig.json';
// import sohoAdminConfig from './data/sohoAdminConfig.json';
// import wamAdminConfig from './data/WamAdminConfig.json';
// import smartivrConfig from './data/smartivrkpi.json';
function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}


function App(props) {
  const [clientConfig, setClientConfig] = React.useState(1);
  const [roles, setRoles] = useState([]);
  const [agentName, setAgentName] = useState("")
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState(null);

  const authRequest = {
    scopes: ["openid", "profile"]
  };

  useEffect(() => {
    getRoles();
    const currLocation = window.location.href;
    if (currLocation.indexOf('/admin') > -1) {
      setClientConfig(2);
    } else if (currLocation.indexOf('/agent') > -1) {
      setClientConfig(3);
    }
    else if (currLocation.indexOf('/kiosk') > -1) {
      setClientConfig(4);
    }
  }, [props])

  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        scopes: ["openid"],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        // console.log(response)
        setApiData(response.idToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setApiData(response.idToken);
          });
        }
      });
    }
  }, [accounts, instance]);


  const getRoles = () => {
    if (accounts.length > 0) {
      const roles = accounts[0].idTokenClaims.roles;
      // console.log(accounts[0])
      setRoles(roles ?? []);
      const name = accounts[0].idTokenClaims.name.split(" ")
      setAgentName(name[0])
    }
    // setRoles(['Admin']);
  }

  const checkAccess = (role) => {
    return roles.includes(role);
  }

  const clientFun = (val) => {
    setClientConfig(val);
    props.history.push(getRouteFragment(val));
  }

  const getRouteFragment = (val) => {
    switch (val) {
      case 1:
        return "/"
      case 2:
        return "/admin"
      case 3:
        return '/agent'
      case 4:
        return '/kiosk'
    }
  }

  const renderConfig = (val, token) => {
    switch (val) {
      case 1:
        return token !== null && <div><Tabs clientFun={clientFun} val={val} config={dixonConfig} hierarchy={HierarchyDixon} emailConfig={dixonEmail} token={token} /></div>
      case 2:
        return token !== null && <Tabs clientFun={clientFun} val={val} config={dixonAdminConfig} emailConfig={dixonAdminEmailConfig} token={token} />
      case 3:
        return token !== null && <AgentScreen clientFun={clientFun} agentName={agentName} />
      case 4:
        return token !== null && <div><Tabs clientFun={clientFun} val={val} config={dixonKioskConfig} hierarchy={HierarchyKiosk} emailConfig={dixonEmail} /></div>


    }
  }


  return (<>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <AutoTimeout />
      {/* {inProgress === "none" && */}

      <div className="App">
        <Switch>
          <Route path="/" component={() => renderConfig(clientConfig)} exact />
          <Route path="/admin" component={() => renderConfig(clientConfig)} exact />
          {checkAccess('Agent') && <Route path="/agent" component={() => renderConfig(clientConfig)} exact />}
          <Route path="/kiosk" component={() => renderConfig(clientConfig)} />
        </Switch>
      </div>
    </MsalAuthenticationTemplate>
    <UnauthenticatedTemplate>
      <p>You are not authorised</p>
    </UnauthenticatedTemplate>

  </>
  );
}

export default withRouter(App);
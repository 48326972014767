/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useEffect, useState } from 'react';
//import config from '../data/config.json';
import { makeStyles } from '@material-ui/core/styles';
import TicketRenderChart from './TicketRenderChart';
import SelectBox from './SelectBox'

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "2%",
        marginLeft: "2%",
        justifyContent: "space-between"
    },
    filterBoxContainer:{
        textAlign:'right',
        width:'100%'
    }
}))


function TicketContainer(props) {
    const classes = useStyles();
    const [renderedChart, setRenderedChart] = useState([]);
    const [filter, setFilter] = useState("");
    const [ticketFilter,setTicketFilter]=useState("All");

    useEffect(() => {
        getData();
        setFilter(props.filterVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    //API call
    const TicketfilterFun=(val)=>{
        setTicketFilter(val);
    }
    const getData = () => {
        let arr = [];
        Object.keys(props.config.TicketChartConfig).map((i, index) => {
            arr.push({ chartConfig: props.config.TicketChartConfig[i], index: index })
        })
        setRenderedChart(arr);
    }

    return (
        <div className={classes.flexContainer}>
            <div className={classes.filterBoxContainer}> 
                <SelectBox filterFun={TicketfilterFun} label="Hours" filterData={props.config.ticketFilter} />
            </div>
           
            {renderedChart.map((i, j) => {
                return <TicketRenderChart key={j} channel={props.channel} filterVal={filter}  BusinessHoursFilter={i.chartConfig.chartType==='TicketStackedBar' || i.chartConfig.chartType==='StackedBar' ? ticketFilter: ''}  apiHeader={props.config.authAPI.authHeaders} config={i.chartConfig} index={i.index + 1} />

            })}
        </div>
    )
}

export default TicketContainer;
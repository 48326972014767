/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import Select from './SelectBox';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import languages from '../../data/language.json';
import platform from '../../data/platform.json';
import Voices from '../../data/voices.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "2%"
    },
    container: {
        display: "flex",
        alignItems: "left",
        justifyContent: "center",
        flexDirection: "column",
        width: "fit-content",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        padding: "1%",
        width: "440px",
        maxHeight: "430px"
    },
    text: {
        fontSize: "20px",
        fontWeight: "bold",
        marginLeft: "10px"
    },
    button: {
        width: "fit-content",
        marginLeft: "10px",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "#e6e6e6"
        },
        color: "Black"
    },
    validate: {
        color: "red"
    }
}))

function ChooseLanguage(props) {
    const [metadata, setMetadata] = useState({ Platform: "", Language: "" });
    const [voice, setVoice] = useState([]);
    const [validate, setValidate] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setVoice(Voices.filter(i => i.country === metadata.Language));
    }, [metadata])

    //Get dropdown values
    const changeMetadata = (val) => {
        setMetadata(m => { return { ...m, ...val } });
    }

    //Validation on submit button
    const handleSubmit = () => {
        if (metadata.Language !== "" && metadata.Platform !== "") {
            props.callback(metadata, voice);
        } else {
            setValidate(true);
        }

    }
    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <div className={classes.text}>Select</div>
                <Select backgroundcolor="#6B2B82" color="white" value={platform} title="Platform" selected={changeMetadata} currentValue={metadata} />
                <Select backgroundcolor="#6B2B82" color="white" value={languages} title="Language" selected={changeMetadata} currentValue={metadata} />
                {/* {metadata.Language !== "" && <Select color="white" backgroundcolor="#6B2B82" value={voice} title="Voice" selected={changeMetadata} currentValue={metadata} />} */}
                <br />
                {validate ? <div className={classes.validate}>All the fields are mandatory</div> : ""}
                <Button className={classes.button} onClick={() => { handleSubmit() }}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default withRouter(ChooseLanguage);
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Hoverbox from './Hoverbox';


const useStyles = makeStyles((theme) => ({
    tooltip: {
        background: 'white',
        boxShadow: '5px 5px 5px 5px #fff'
    },
    customArrow: {
        color: 'rgb(202, 191, 191)',
    },
    container: {
        display: "flex"
    },
    box: {
        display: "flex",
        width: "180px",
        height: "50px",
        margin: "4px 10px 10px 10px",
        border: "1px solid grey",
        borderRadius: "3px",
        flexDirection: "column",
        padding: "0.5% 0 1% 1%",

    },
    text: {
        fontSize: "13px",
        color: "grey",
    },
    values: {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: '2px',
        height: '40px',
        display: 'center',
        marginLeft: "70px"
    },
    summary: {
        marginLeft: "10px",
        fontSize: "18px"
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // height: "calc(100% - 120px)"
    },
}))



function RoiData(props) {
    const classes = useStyles();
    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);
    //API call
    const apiCall = () => {
        let url;

        // url = props.useCaseFilter != undefined && props.channel != "email" ?  
        // props.roiConfig.endpoint + "/" + props.useCaseFilter + "/" + props.from + "/" + props.to:
        // props.roiConfig.endpoint + "/" + props.from + "/" + props.to;

        url = props.useCaseFilter != undefined ?
            props.useCaseFilter != "StoreTillRestart" ?
                props.roiConfig.endpoint + "/" + props.useCaseFilter + "/" + props.from + "/" + props.to :
                props.roiConfig.endpoint + "/" + props.from + "/" + props.to :
            props.roiConfig.endpoint + "/" + props.from + "/" + props.to;

        // console.log(url);


        if (props.from !== "" && props.to !== "") {

            setLoad(false);
            const basicAuth = props.config.authAPI.authHeaders;
            axios.get(url,
                { headers: { "Authorization": basicAuth } },
                //      {
                //     auth: {
                //         username: "admin",
                //         password: basicAuth
                //     }
                // }
            )

                .then((res) => {

                    (props.useCaseFilter !== undefined && props.useCaseFilter != "StoreTillRestart") ?
                        setData(res.data[0]) :
                        setData(res.data);
                    setLoad(true);
                    // console.log(res);
                })

        }



    }



    useEffect(() => {
        apiCall();
        // console.log(props.filterSDCalls);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.from, props.to, props.useCaseFilter])




    return (
        <div className={classes.container}>
            {props.channel != "email" && props.useCaseFilter === "All" && <div> <div className={classes.box}>
                <div className={classes.text}>Total SD Calls</div>
                <div className={classes.values}>{props.filterSDCalls}</div>
            </div> </div>}
            {Object.keys(props.roiConfig.keyValue).map((i, index) => {
                return <div key={index}>
                    <div className={classes.box}>
                        <div className={classes.text}> {props.roiConfig.keyValue[i]}</div>
                        <div className={classes.values}>
                            {load ? <>{typeof data[i] === "number" ? data[i] % 1 === 0 ? data[i] : data[i].toFixed(2) : data[i]}</> :
                                <div className={classes.loading}><CircularProgress size="20px" /></div>}
                        </div>

                        {load && props.useCaseFilter != undefined && <Hoverbox data={data} value={i} config={props.roiConfig.tooltip} />}

                    </div>
                </div>
            })}
        </div>
    )
}

export default RoiData;
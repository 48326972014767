/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import stylesConfig from '../../data/stylesConfig.json';
import { withRouter } from "react-router-dom";
import AudioGenerator from '../AudioGeneratorTool/AudioGenerator';
import Performance from './Performance';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box paddingLeft="0px" paddingRight="0px">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function SimpleTabs(props) {
    const classes = useStyles();
    const { value } = props;

    return (
        <div className={classes.root}>
            <TabPanel value={value} index={0}>
                <Performance config={props.config} emailConfig={props.emailConfig} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AudioGenerator config={props.config} emailConfig={props.emailConfig} />
            </TabPanel>

            {/* <TabPanel value={value} index={tabIndex}>
                    <Switch>
                        <Route path="/admin/" component={() => <Performance config={props.config} />} exact />
                        {props.config.audioTool.audioGenerator && <Route path="/admin/audio-generator" component={() => <AudioGenerator config={props.config} />} exact />}
                    </Switch>
                </TabPanel> */}
        </div>
    );
}

export default withRouter(SimpleTabs);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        backgroundColor: stylesConfig.basic.appbarColor,
        position: "static",
        width: "100%",
        zIndex: "1",
    },
    tabData: {
        textTransform: "inherit",
        fontSize: "15px",
        minWidth: "50px",
        color: "black",
        marginTop: "8px"
    },
    box: {
        backgroundColor: stylesConfig.basic.appbarColor,
        boxShadow: "5px 5px 15px #A8A4AD"
    },
    performance: {
        padding: "2%"
    }
}));
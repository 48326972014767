/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Composedchart from "./Composedchart";
import RoiData from './RoiData';
import axios from 'axios';
import DatePicker from './DatePicker';
// import moment from "moment";


const useStyles = makeStyles((theme) => ({
    switch: {
        display: "flex",
        marginTop: "40px",
        marginBottom: "20px"
    },
    space: {
        marginLeft: "80px",
    },
    leftDatePicker:
    {
        marginRight: theme.spacing(1),
        width: 150,
        fontSize: "12px",
        marginBottom: "15px",
        marginLeft: '10px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: 150,
        fontSize: "12px",
        marginBottom: "15px",
        marginLeft: '45px'
    },
    heading: {
        marginTop: '30px',
        fontSize: '17px',
        marginLeft: '10px'
    }
}))
function TicketPerformance(props) {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState([]);

    const get40daysDate = () => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(fromDate);
        const secondDate = new Date(toDate);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        if (diffDays < 40) {
            return fromDate + "/" + toDate;
        } else {
            let today = new Date(props.to);
            let last40Days = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 40
            );
            var dateObj = new Date(last40Days);
            var month = dateObj.getUTCMonth() + 1; //months from 1-12
            var day = dateObj.getUTCDate();
            var year = dateObj.getUTCFullYear();
            let newdate = year + "-" + month + "-" + day;
            return newdate + "/" + toDate;
        }
    };

    const apiCall = () => {
        if (fromDate !== '' && toDate !== '') {
            const basicAuth = props.config.authAPI.authHeaders;
            let endpoint = `${props.config.chartConfig.logaTicketSuccessCalls.endpoint}/${get40daysDate()}`;
            axios.get(endpoint,
                { headers: { "Authorization": basicAuth } },
                //      {
                //     auth: {
                //         username: "admin",
                //         password: basicAuth
                //     }
                // }
            )
                .then((res) => {
                    setData(res.data);
                });

        }
    }

    useEffect(() => {
        apiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate])

    //read to date
    // const to = (val) => {
    //     setToDate(val);
    // }
    // const getDate = (dateparam) => {
    //     //   console.log(dateparam);
    //     var date = new Date();
    //     var min = new Date(dateparam);
    //     var d = String(date.getDate()).padStart(2, '0');
    //     var m = String(date.getMonth() + 1).padStart(2, '0');
    //     var y = date.getFullYear();
    //     date = y + '-' + m + '-' + d;
    //     console.log(date);
    //     return date;
    // }

    const from = (val) => {
        setFromDate(val);
        // setInitFromDate(val);
    }

    const to = (val) => {
        setToDate(val);
        // setInitToDate(val);
    }

    // const getPlaceholderDate = (dateparam) => {
    //     var date = new Date();
    //     var min = new Date(dateparam);
    //     var d = String(min.getDate()).padStart(2, '0');
    //     var m = String(min.getMonth() + 1).padStart(2, '0');
    //     var y = min.getFullYear() - 1;
    //     date = m + '/' + d + '/' + y;
    //     return date;
    // }
    // const handleDateChange = (date) => {
    //     setSelectedDate(date._d);
    //     setFromDate(getDate(date._d));
    // }
    // const TextFieldComponent = (props) => {
    //     return <TextField {...props} disabled={true} />
    // }
    // const handleDateToChange = (date) => {
    //     // setSelectedDate(date);
    //     setToDate(getDate(date._d));
    // }
    return (
        <div>
            <div className={classes.heading}>Ticket Creation</div>
            {/* <div className={classes.switch}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    clearable
                    value={selectedDate}
                    placeholder={getPlaceholderDate(props.date)}
                    onChange={date => handleDateChange(date)}
                    className={classes.leftDatePicker}
                    format="MM-DD-yyyy"
                    disableFuture
                    invalidDateMessage={false}
                    allowKeyboardControl={false}
                    TextFieldComponent={TextFieldComponent}
                />
                 {fromDate !== "" &&
                    <KeyboardDatePicker
                        clearable
                        value={toDate}
                        placeholder={getDate(selectedDate)}
                        onChange={date => handleDateToChange(date)}
                        minDate={new Date(selectedDate)}
                        format="MM-DD-yyyy"
                        className={classes.textField}
                        disableFuture
                        invalidDateMessage={false}
                        InputProps={{ allowKeyboardControl: false }}
                        TextFieldComponent={TextFieldComponent}
                    />
                }
            </MuiPickersUtilsProvider>
            </div> */}
            <DatePicker from={from} to={to} />
            {fromDate !== "" && toDate !== "" && <>
                <RoiData from={fromDate} to={toDate} config={props.config} roiConfig={props.config.ticketroidata} />
                <Composedchart title="Ticket status" legend={"Ticket Status"} data={data} />
            </>}

        </div>

    )
}
export default TicketPerformance;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Select from './SelectBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Pitch from '../../data/pitch.json';
import Volume from '../../data/volume.json';
import Break from '../../data/break.json';
import Rate from '../../data/rate.json';
import Interpret from '../../data/interpretAs.json';
import CropIcon from '@material-ui/icons/Crop';
import DateValues from '../../data/date.json';
import Time from '../../data/time.json';
import Tooltip from '@material-ui/core/Tooltip';
import './Styles.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    options: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "left"
    },
    txtContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        width: "83%"
    },
    textField: {
        fontSize: "15px",
        padding: "10px",
        width: "100%"
    },
    text: {
        display: "flex",
    },
    icons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        float: "right",
        width: "30%"
    },
    iconColor: {
        fill: "#6B2B82"
    },
    submitAlert: {
        width: "160px",

    },
}))

//To display tooltips
const tooltipText = {
    "voice": "This element is used to select a voice",
    "break": "This element insert pauses. The pause will be added end of this statement.",
    "pitch": "This element is used to vary the frequency of the voice.",
    "rate": "This element is used to change the speed at which the statement is uttered.",
    "volume": "This element is used to modify the loudness of the voice.",
    "interpret": "Interpret is used in scenario where telephone numbers, date, time or the word needs to spelled out."
}
function TextCustomEdit(props) {
    const classes = useStyles();
    const [edit, setEdit] = useState(false);
    const [metadata, setMetadata] = useState(props.tags);
    const [expandContent, setExpandContent] = useState(false);
    // const [isSubmit, setIsSubmit] = useState(false);
    const textInputRef = useRef(null);
    // const [onSubmit, setOnSubmit] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = React.useState(false);


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    //To get the dropdown values from parent
    const changeMetadata = (val) => {
        setMetadata(m => {
            props.submit(props.index, { ...m, ...val });
            return { ...m, ...val }
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    // const onsubmit = (data) => {
    //     setOnSubmit(data)
    //     setIsSubmit(true);
    // }

    const toSetEdit = (editedValue) => {
        if (editedValue !== "") {
            setEdit(edit => !edit)
        } else {
            setOpen(true);
            setErrorMessage("Edited list item can't be empty");
        }
    }

    const onPasting = (e, index, list) => {
        e.stopPropagation();
        e.preventDefault();
        e.target.innerText = e.target.innerText + e.clipboardData.getData("Text");
    }


    useEffect(() => {
        setMetadata(m => { return { ...m, ...props.tags } });
    }, [props])

    return [
        <div>
            <div className={classes.text}>
                <div className={classes.txtContainer}> <div className={classes.textField} ref={textInputRef} contentEditable={edit} onPaste={(event) => onPasting(event)}>{props.text} </div></div>
                {!edit ?
                    <div className={classes.icons}>
                        <div className={classes.submitAlert}>
                            <div >
                                {metadata.Break !== "none" && <img src="https://img.icons8.com/ios-filled/100/fa314a/circled-b.png" width="25px" height="25px" alt="img.png" />}
                                {metadata.Pitch !== "default" && <img src="https://img.icons8.com/ios-filled/100/9B59B6/circled-p.png" width="25px" height="25px" alt="img.png" />}
                                {metadata.Rate !== "default" && <img src="https://img.icons8.com/ios-filled/100/E67E22/circled-r.png" width="25px" height="25px" alt="img.png" />}
                                {metadata.Volume !== "default" && <img src="https://img.icons8.com/ios-filled/100/26e07f/circled-v.png" width="25px" height="25px" alt="img.png" />}
                                {metadata.Interpret !== "" && <img src="https://img.icons8.com/ios-filled/100/4a90e2/circled-i.png" width="25px" height="25px" alt="img.png" />}
                                {metadata.Format !== "" && <img src="https://img.icons8.com/ios-filled/100/34495E/circled-f.png" width="25px" height="25px" alt="img.png" />}
                            </div>

                        </div>
                        <IconButton height="47px" edge="end" aria-label="comments" color="primary" onClick={() => { setExpandContent(expandContent => !expandContent) }}>
                            {!expandContent ? <ExpandMoreIcon className={classes.iconColor} /> : <ExpandLessIcon className={classes.iconColor} />}
                        </IconButton>
                        <Tooltip title="Click to edit text"><IconButton edge="end" aria-label="comments" color="primary" onClick={() => setEdit(edit => !edit)}>
                            <EditIcon className={classes.iconColor} />
                        </IconButton></Tooltip>
                        <Tooltip title="Play"><IconButton edge="end" aria-label="comments" color="primary" onClick={() => { props.play(props.index) }}>
                            <PlayCircleOutlineIcon className={classes.iconColor} />
                        </IconButton></Tooltip>
                        <Tooltip title="Select some text and click to move it to next item"><IconButton edge="end" aria-label="comments" color="primary" onClick={() => { props.selectedText(props.index, window.getSelection().toString(), window.getSelection().anchorOffset, window.getSelection().focusOffset) }}>
                            <CropIcon className={classes.iconColor} />
                        </IconButton></Tooltip>
                        <Tooltip title="Delete"><IconButton edge="end" aria-label="comments" color="secondary" onClick={() => { props.delete(props.index) }}>
                            <DeleteIcon style={{ fill: "#DC143C" }} />
                        </IconButton></Tooltip>
                    </div> : <div className={classes.icons} >

                        <div className={classes.submitAlert} ></div>  <IconButton edge="end" aria-label="comments" color="primary" onClick={() => { return (props.editData(edit, props.index, textInputRef.current.innerText), toSetEdit(textInputRef.current.innerText)) }}>
                            <AddIcon className={classes.iconColor} />
                        </IconButton>
                    </div>
                }
            </div>
            {!edit && expandContent && <div className={classes.options}>
                <Select tooltip={tooltipText.voice} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={props.voice} title="Voice" selected={changeMetadata} currentValue={metadata} />
                <Select tooltip={tooltipText.break} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={Break} title="Break" selected={changeMetadata} currentValue={metadata} />
                <Select tooltip={tooltipText.pitch} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={Pitch} title="Pitch" selected={changeMetadata} currentValue={metadata} />
                <Select tooltip={tooltipText.rate} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={Rate} title="Rate" selected={changeMetadata} currentValue={metadata} />
                <Select tooltip={tooltipText.volume} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={Volume} title="Volume" selected={changeMetadata} currentValue={metadata} />
                <Select tooltip={tooltipText.interpret} color="white" fontsize="12px" backgroundcolor="#6B2B82" value={Interpret} title="Interpret" selected={changeMetadata} currentValue={metadata} />
                {metadata.Interpret === "date" && <Select fontsize="12px" color="white" backgroundcolor="#6B2B82" value={DateValues} title="Format" selected={changeMetadata} currentValue={metadata} />}
                {metadata.Interpret === "time" && <Select fontsize="12px" color="white" backgroundcolor="#6B2B82" value={Time} title="Format" selected={changeMetadata} currentValue={metadata} />}
                <Button style={{ height: "30px" }} color="white">Submit</Button>
            </div>}
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div >
    ]
}

export default TextCustomEdit;
import axios from "axios";
import React, { useState, useEffect } from "react";
import readXlsxFile from "read-excel-file";
import Chart from "./chart";
import Composedchart from "./Composedchart";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '60px'
  },
}));

function ExcelRead(props) {

  const [sdData, setSdData] = useState([]);
  const [passwordResetData, setPasswordResetData] = useState([]);
  const [ticketStatusData, setTicketStatusData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    axios
      .get(props.config.excelPath.endpoint, {
        responseType: "blob",
      })
      .then((res) => {
        readXlsxFile(res.data).then((rows) => {
          var objs = rows.map(function (x) {
            return {
              date: new Date(x[0]),
              count: x[1],
              passwordReset: x[2],
              ticketStatus: x[3],
            };
          });
          setSdData(objs);
          props.sdCallsData(objs);
        });
      });
    getAPIdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);


  //Rewrite date in required format
  const getDate = () => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(props.from);
    const secondDate = new Date(props.to);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays < 40) {
      return props.from + "/" + props.to;
    } else {
      let today = new Date(props.to);
      let last40Days = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 40
      );
      var dateObj = new Date(last40Days);
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;
      return newdate + "/" + props.to;
    }
  };

  const overAllData = (tickStatus, passReset) => {
    let resultArr = [];


    tickStatus.sort(function (a, b) {
      return new Date(a.day) - new Date(b.day);
    });

    passReset.sort(function (a, b) {
      return new Date(a.day) - new Date(b.day);
    });


    let resultAsPerDates = getDate();
    resultAsPerDates = resultAsPerDates.split("/");
    // let startDate = tickStatus[0] && new Date(tickStatus[0].day) < passReset[0] && new Date(passReset[0].day) ? tickStatus[0] && tickStatus[0].day : passReset[0] && passReset[0].day;
    // let greaterLength = tickStatus.length > passReset.length ? tickStatus.length : passReset.length;
    for (let d = new Date(resultAsPerDates[0]); d <= new Date(resultAsPerDates[1]); d.setDate(d.getDate() + 1)) {
      let dd = moment(d).format("MMM-D-YYYY");
      let tResult = {
        status: false,
        data: {}
      };
      let pResult = {
        status: false,
        data: {}
      };
      for (let j = 0; j < tickStatus.length; j++) {
        let tDay = moment(tickStatus[j].day).format("MMM-D-YYYY");
        if (dd === tDay) {
          tResult = {
            status: true,
            data: tickStatus[j]
          };
          break;
        }
      }
      for (let k = 0; k < passReset.length; k++) {
        let pDay = moment(passReset[k].day).format("MMM-D-YYYY");
        if (dd === pDay) {
          pResult = {
            status: true,
            data: passReset[k]
          };
          break;
        }
      }


      if (tResult.status && pResult.status) {
        resultArr.push({
          day: tResult.data.day,
          Totalcount: tResult.data.Totalcount + pResult.data.Totalcount,
          successcount: tResult.data.successcount + pResult.data.successcount,
        });
      } else {
        if (tResult.status) {
          resultArr.push({
            day: tResult.data.day,
            Totalcount: tResult.data.Totalcount,
            successcount: tResult.data.successcount,
          });
        }
        if (pResult.status) {
          resultArr.push({
            day: pResult.data.day,
            Totalcount: pResult.data.Totalcount,
            successcount: pResult.data.successcount,
          });
        }
      }
    }

    return resultArr;
  };

  //API Call
  const getAPIdata = () => {
    const basicAuth = props.config.authAPI.authHeaders;
    let passwordResetEndpoint =
      props.config.passwordResetChart.endpoint + "/" + getDate();
    let ticketStatusEndpoint =
      props.config.ticketStatusChart.endpoint + "/" + getDate();
    axios
      .get(passwordResetEndpoint,
        { headers: { "Authorization": basicAuth } },
        //      {
        //     auth: {
        //         username: "admin",
        //         password: basicAuth
        //     }
        // }
      )
      .then((res) => {
        setPasswordResetData(res.data);
      });
    axios
      .get(ticketStatusEndpoint, {
        auth: {
          username: "admin",
          password: basicAuth,
        },
      })
      .then((res) => {
        setTicketStatusData(res.data);
        // console.log(res);
      });
  };

  return (
    <div className={classes.container}>
      {/* {props.useCaseFilter === "TicketStatus" ? <Composedchart legend="Ticket Status" title="Ticket status" data={ticketStatusData} /> : props.useCaseFilter === "PasswordReset" ?
                <Composedchart title="Password reset" legend="Password Reset" data={passwordResetData} /> :
                <Composedchart title="Password reset and Ticket status" legend={""} data={passwordResetData.length === 0 ? ticketStatusData : overAllData(ticketStatusData, passwordResetData)} />} */}
      {props.showExcelData ? (
        <Chart
          totalSDCalls={props.totalSDCalls}
          showExcelData={props.showExcelData}
          sdCalls={sdData}
          ticketStatus={ticketStatusData}
          passwordReset={passwordResetData}
          from={props.from}
          to={props.to}
          useCaseFilter={props.useCaseFilter}
          config={props.config}
        />
      ) : props.useCaseFilter === "TicketStatus" ? (
        <Composedchart
          title="Ticket status"
          legend={"Ticket Status"}
          data={ticketStatusData}
        />
      ) : props.useCaseFilter === "PasswordReset" ? (
        <Composedchart
          title="Password reset"
          legend={"Password Reset"}
          data={passwordResetData}
        />
      ) : (
              <Composedchart
                title="Password reset and Ticket status"
                legend={""}
                data={overAllData(passwordResetData, ticketStatusData)}
              />
            )}
    </div>
  );
}

export default ExcelRead;

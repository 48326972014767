/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import stylesConfig from '../../data/stylesConfig.json';
import "./Styles1.css";

const notFound = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "120px",
    fontSize: "20px",
    color: "#0077b6"
}
function HorizontalStackedBarChart(props) {
    const [formatedData, setFormatedData] = useState([]);

    //set chart color
    const fillColor = (index) => {
        let arr = [];
        arr = props.chartConfig.color.map(i => i)
        return arr[index];
    }
    let param1 = props.chartConfig.xaxis[0];
    let param2 = props.chartConfig.xaxis[1];
    let param3 = props.chartConfig.xaxis.length === 3 ? props.chartConfig.xaxis[2] : '';
    let obj = {};
    // props.chartConfig.xaxis.map((i,key)=>{
    //     return obj[key]=0;    
    // });

    obj[param1] = 0;
    obj[param2] = 0;
    obj[param3] = props.chartConfig.xaxis.length === 3 ? 0 : '';
    //get data in required format
    const formatData = () => {
        let result = [];
        props.data.map(key => {
            let currentIndex = result.findIndex((i) => i.reason === key.reasonOfFailure)
            if (currentIndex < 0) {
                result.push({
                    reason: key.reasonOfFailure,
                    ...obj
                })
                currentIndex = result.length - 1;
            }
            result[currentIndex][param1] = key.useCaseType === param1 ? result[currentIndex][param1] + key.count : result[currentIndex][param1];
            result[currentIndex][param2] = key.useCaseType === param2 ? result[currentIndex][param2] + key.count : result[currentIndex][param2];
            if (param3 !== '') {
                result[currentIndex][param3] = key.useCaseType !== param1 && key.useCaseType !== param2 ? result[currentIndex][param3] + key.count : result[currentIndex][param3];
            }

        })
        result.sort((a, b) => parseInt(b.passwordReset) - parseInt(a.passwordReset));
        console.log(result)
        setFormatedData(result);
    }
    const formatData2 = () => {
        let result = [];
        props.data.map(key => {
            let currentIndex = result.findIndex((i) => i.reason === key.usecase)
            if (currentIndex < 0) {
                result.push({
                    reason: key.usecase,
                    ...obj
                })
                currentIndex = result.length - 1;
            }
            result[currentIndex][param1] = key.sentiment === param1 ? result[currentIndex][param1] + key.count : result[currentIndex][param1];
            result[currentIndex][param2] = key.sentiment === param2 ? result[currentIndex][param2] + key.count : result[currentIndex][param2];
            if (param3 !== '') {
                result[currentIndex][param3] = key.sentiment !== param1 && key.sentiment !== param2 ? result[currentIndex][param3] + key.count : result[currentIndex][param3];
            }

        })
        // result.sort((a, b) => parseInt(b.passwordReset) - parseInt(a.passwordReset));
        // console.log(result)
        setFormatedData(result);
    }

    useEffect(() => {
        // formatData();
        props.tab !== 4 ? formatData() : formatData2()
    }, [props])

    const renderColorfulLegendText = (value, entry) => {
        return <span style={{ color: 'black' }}>{value}</span>;
    };

    return (
        <>
            <ResponsiveContainer height={props.tab !== 4 ? 490 : 260} width={'100%'}>
                <BarChart
                    layout="vertical"
                    width={stylesConfig.barAndLine.chartWidth}
                    height={props.tab !== 4 ? stylesConfig.barAndLine.chartHeight : 260}
                    data={formatedData}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <XAxis hide type="number" />
                    <YAxis width={120} type="category" dataKey="reason" fontSize="12" />
                    <Tooltip />
                    <Legend formatter={renderColorfulLegendText} />
                    {props.chartConfig.xaxis.map((i, index) => {
                        return <Bar dataKey={i} key={index} stackId="a" fill={fillColor(index)} />
                    })}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}

export default HorizontalStackedBarChart;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import StackedAreaChart from './StackedAreaChart';
import LineChart from './LineChart';
import StackedBarChart from './StackedBarChart';
import Pie from './Pie';
import stylesConfig from '../../data/stylesConfig.json';
import Card from '@material-ui/core/Card';
import { CircularProgress } from '@material-ui/core';
import List from './List';
import HorizontalStackedBar from './HorizontalStackedBar';

const useStyles = makeStyles((theme) => ({
    card1: {
        width: stylesConfig.basic.cardWidth,
        height: stylesConfig.basic.cardHeight,
        borderRadius: 0,
        marginBottom: "10px"
    },
    card2: {
        width: "49.2%",
        // width: "flex",
        height: stylesConfig.basic.cardHeight,
        // height: "400px",
        borderRadius: 0,
        marginBottom: "10px",
        // overflowY: "auto",
        paddingLeft: "5px"
    },
    card3: {
        width: "34.6%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    card6: {
        width: "49.2%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    card4: {
        width: "64.5%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    card5: {
        width: "49.2%",
        borderRadius: 0,
        marginBottom: "10px",
        height: "580px",
        overflowY: "auto"
    },
    title: {
        marginTop: "15px",
        marginLeft: "15px",
        fontSize: "20px",
        marginBottom: "20px"
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100% - 120px)"
    }
}))

function RenderChart(props) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);
    const [date, setDate] = useState("");
    const [filter, setFilter] = useState(7);

    //format date 
    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '-' + mm + '-' + yyyy;
        setDate(today)
    }


    useEffect(() => {
        setFilter(props.filterVal)
        getDate();
        const timer = setTimeout(() => {
            getData();
        }, 1000);
        return () => clearTimeout(timer);
    }, [props])

    //API call
    const getData = async () => {

        let endpoint = ""
        props.config.queryParams === "true" ? endpoint = props.config.endpoint + "/" + props.filterVal : endpoint = props.config.endpoint;
        const basicAuth = props.apiHeader;
        await axios.get(endpoint,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then(res => {
                setData(res.data);
                setLoad(true);
                // console.log(endpoint);
                // console.log(res);
            })
    }

    //Check type of the chart and render
    const renderChart = (chart, data) => {
        if (data) {
            switch (chart.chartType) {
                case "StackedBar":
                    return <div><StackedBarChart header={props.apiHeader} data={data} chartConfig={chart} date={date} filter={filter} /></div>
                case "StackedArea":
                    return <div><StackedAreaChart data={data} chartConfig={chart} date={date} /></div>;
                case "Line":
                    return <div><LineChart header={props.apiHeader} data={data} chartConfig={chart} date={date} filter={filter} /></div>;
                case "Pie":
                    return <div><Pie data={data} chartConfig={chart} date={date} /></div>;
                case "List":
                    return <div><List data={data} channel={props.channel} tab={props.tab} header={props.apiHeader} chartConfig={chart} date={date} filter={filter} /></div>
                case "HorizontalStackedBar":
                    return <div><HorizontalStackedBar tab={props.tab} data={data} chartConfig={chart} date={date} /></div>
                default:
                    return <div></div>
            }
        }

    }

    const cardSize = () => {
        switch (props.config.chartType) {
            case "Pie": return props.channel !== "email" ? props.tab !== 4 ? classes.card2 : classes.card2 : classes.card1;
            case "List": return props.tab !== 4 ? classes.card3 : classes.card6;;
            case "HorizontalStackedBar": return props.tab !== 4 ? classes.card4 : classes.card2;
            case "StackedArea": return classes.card2;
            case "LineChart": return classes.card2;
            default: return classes.card1
        }
    }
    return (
        <>
            <Card className={cardSize()}>
                <div className={classes.title}>{props.config.title}</div>
                {load ? renderChart(props.config, data) : <div className={classes.loading}><CircularProgress /></div>}
            </Card>
        </>
    )
}

export default RenderChart;
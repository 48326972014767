import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from './Container';
import TicketContainer from './TicketContainer';
import stylesConfig from '../../data/stylesConfig.json';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin:'10px 25px'
  },
  tabBox: {
    backgroundColor: "#C0C0C0",
  }
 
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {filterVal,channel,config}=props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabBox}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{ backgroundColor:'#E4E4E4'}}>
          <Tab label="Automated Resolution" {...a11yProps(0)} />
          <Tab label="Ticket Logging" {...a11yProps(1)} />
        </Tabs>
        </div>
      <TabPanel value={value} index={0}>
        <Container filterVal={filterVal} channel={channel} config={config} />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <TicketContainer filterVal={filterVal} channel={channel} config={config} />
      </TabPanel>
    </div>
  );
}

/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useLayoutEffect, useRef } from "react";
import TreeChart from "d3-org-chart";

export const OrgChartComponent = (props, ref) => {
    const d3Container = useRef(null);
    let chart = null;

    useLayoutEffect(() => {
        if (props.data && d3Container.current) {
            if (!chart) {
                chart = new TreeChart();
            }
            //chart = new TreeChart();

            chart
                .container(d3Container.current)
                .data(props.data)
                .svgWidth(500)
                .initialZoom(0.190)
                .render();
        }
    }, [props.data, d3Container.current]);

    return (
        <div>
            <div ref={d3Container} />
        </div>
    );
};

/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { Today } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    leftDatePicker:
    {
        marginRight: theme.spacing(1),
        width: 150,
        fontSize: "12px",
        marginBottom: "15px",
        marginLeft: '10px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: 150,
        fontSize: "12px",
        marginBottom: "15px",
        marginLeft: '45px'
    }
}));
function Roi(props) {
    
    const classes = useStyles();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedDate, setSelectedDate] = useState(fromDate)
   
    
    //Read to date
    // const handleToEvent = (value) => {
    //     setToDate(value);
    //     props.to(value)
    // }
    //Read from date
    // const handleFromEvent = (value) => {
    //     setFromDate(value);
    //     props.from(value);
    // }
    // useEffect(() => {
    //     getDate();
    // }, [props])
    const getDate = (dateparam) => {
        var date = new Date();
        var min = new Date(dateparam);
        var d = String(min.getDate()).padStart(2, '0');
        var m = String(min.getMonth() + 1).padStart(2, '0');
        var y = min.getFullYear();
        date = y + '-' + m + '-' + d;      
        return date;
    }

    const handleDateChange = (date) => {
        setSelectedDate(getDate(date._d));
        setFromDate(getDate(date._d));
        props.from(getDate(date._d))
    }
    const TextFieldComponent = (props) => {
        return <TextField {...props} disabled={true} />
    }
    const handleDateToChange = (date) => {
      
        setToDate(getDate(date._d));
        props.to(getDate(date._d));
    }
 
    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    clearable
                    value={props.value!==undefined ? props.value : selectedDate}
                    placeholder={selectedDate}
                    label="From"
                    onChange={date => handleDateChange(date)}
                    // minDate={new Date(fromDate)} 
                    className={classes.leftDatePicker}
                    format="MM-DD-yyyy"
                    disableFuture
                    invalidDateMessage={false}
                    allowKeyboardControl={false}
                    TextFieldComponent={TextFieldComponent}
                />

                {/* <TextField
                id="date"
                label="From"
                type="date"
                // defaultValue={props.date}
                onChange={(event) => { handleFromEvent(event.target.value) }}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ min: minDate, max: props.date }}
            /> */}
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            /> */}
            
               
                    {/* // <TextField
                    //     id="date"
                    //     label="To"
                    //     type="date"
                    //     style={{ marginLeft: "100px" }}
                    //     // defaultValue={props.date}
                    //     onChange={(event) => { handleToEvent(event.target.value) }}
                    //     className={classes.textField}
                    //     InputLabelProps={{
                    //         shrink: true,
                    //     }}
                    //     inputProps={{ min: fromDate, max: props.date }}
                    // />
                    // } */}
                    
                    <KeyboardDatePicker
                        clearable
                        value={props.value!==undefined? props.toDate : toDate}
                        placeholder={getDate(selectedDate)}
                        onChange={date => handleDateToChange(date)}
                        // minDate={new Date(fromDate)}
                        label="To"
                        format="MM-DD-yyyy"
                        className={classes.textField}
                        disableFuture
                        invalidDateMessage={false}
                        InputProps={{ allowKeyboardControl: false }}
                        TextFieldComponent={TextFieldComponent}
                    />
                
            </MuiPickersUtilsProvider>
            {/* </MuiPickersUtilsProvider> */}
        </>
    )
}
export default Roi;
import logo from '../AgentScreen/data/currys.svg';
// import './App.css';
import './Styles.css';
// import Cards from './UserDetails';
// import {Link} from 'react-router-dom';


function AgentScreen({ clientFun, agentName }) {
    console.log(agentName)
    const url = "https://smartivr-dixon-kiosk-dev.mindtree.com/agent/Agent" + agentName + "?token=U2FsdGVkX1%2BMFeSKdz%2Bmoo8KueaVS3N1tyOc%2FIMIqxU%3D"
    return (
        <>
            <div className="mainContainer">
                <div className="videoContainer">
                    <div className="connect_screen_button1" style={{ position: "absolute", top: "2.5%", right: "2%", }} onClick={() => clientFun(1)}>
                        Home
                </div>
                    <iframe src={url} allow="camera; microphone" frameBorder="0" style={{ height: "100%", width: "100%", display: "block", margin: 0 }}></iframe>
                </div>
                {/* <div className="detailsContainer">


                    <header className='header'>
                        <img style={{ marginLeft: "10px", marginTop: "20px" }} src={logo} alt="Logo" width="50px" height="50px" />
                    </header>
                    <div className='detailsBodyContainer'>
                        <Cards />
                    </div>




                </div> */}
            </div>
        </>
    );
}

export default AgentScreen;

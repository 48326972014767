/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
const useStyles = makeStyles((theme) => ({

    tooltip:{
        width:"200px",
        background:'white',
        marginTop: "-5px",
        boxShadow:'5px 5px 5px 5px #fff'
    },
    customArrow: {
        color: 'rgb(202, 191, 191)',
        marginLeft: "130px",
        marginTop: "-20px"
      },
  
    tooltipBox:{
        height:"50px",
        border:'2px solid #fff',
    },
    tooltipValueBox:{
        padding: "1px 5px",
        fontSize:'12px',
        color:'black',
    },
    tooltipValues:{
        marginTop:'10px'
    },
    tooltipAgentTransferBox:{
        width:"200px",
        height:"50px"
    }

}))


const Hoverbox=(props)=>{

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(!open);
    };


    useEffect(() => {
        // console.log(props.value)
    }, [props.value])


 
  
    return (
    <>
    {Object.keys(props.config).map((i) => {   
    return <>
        { props.value==i &&
        <>
            <div className={classes.customArrow} >
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <ArrowDropDownCircleOutlinedIcon onClick={handleTooltipOpen} style={{color:'grey',cursor:'pointer',fontSize:'16px'}}/>
                </ClickAwayListener>
            </div>
            { open &&
                <div className={classes.tooltip}>
                    <div className={classes.tooltipValueBox}>
                        <div className={classes.tooltipValues}> 
                            {Object.keys(props.config[i]).map((j, index) => {
                                return  <div key={index}>{props.config[i][j]+" "+props.data[j]}<br/></div> 
                                })}
                        </div> 
                    </div> 
                </div>
            }
        </>
        } 
    </>
    })} 
  </>)
}

export default Hoverbox;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginBottom: "20px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        backgroundColor: "white",
        width: "200px",
        paddingLeft: "10px"
    },
    icon: {
        fill: "black",
    },
    value: {
        padding: "5px"
    }
}));

export default function SimpleSelect(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const { filterFun } = props;

    //Update current value when filter changes
    const handleChange = (event) => {
        setAge(event.target.value);
        filterFun(event.target.value)

    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    {props.label}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={age}
                    onChange={handleChange}
                    className={classes.selectEmpty}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                    }}
                >
                    {Object.keys(props.filterData).map((i, index) => {
                        return <MenuItem key={index} value={props.filterData[i]}>{i}</MenuItem>
                    })}
                    {/* <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={90}>Last 90 days</MenuItem>
                    <MenuItem value={360}>An year</MenuItem> */}
                </Select>
            </FormControl>
        </div>
    );
}


/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from "react";
import { OrgChartComponent } from './OrgChart';
import axios from "axios";

//getting data to required format
const formatData = (data) => {
    let result = [];
    data.map(key => {
        let currentIndex = result.findIndex((i) => i.failedAt.toLowerCase() === key.failedAt.toLowerCase())
        if (currentIndex < 0) {
            result.push({
                failedAt: key.failedAt,
                count: 0,
            })
            currentIndex = result.length - 1;
        }
        result[currentIndex].count = result[currentIndex].count + key.count;
    })
    return result;
}
const App = props => {
    const [data, setData] = useState(null);
    const getData = async () => {
        const basicAuth = props.config.authAPI.authHeaders;
        await axios.get(`${props.config.hierarchyChart.endpoint}/${props.filterVal}`,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then(res => {
                let data1 = [];
                data1 = props.hierarchy.map(d => {
                    const width = Math.round(Math.random() * 50 + 300);
                    const height = Math.round(Math.random() * 20 + 150);
                    const expanded = true; //d.id=="O-6"
                    const resultData = formatData(res.data);
                    const count = getCount(d.name, resultData);
                    return {
                        nodeId: d.id,    // node identificator
                        parentNodeId: d.parentId,  // parent node identificator
                        width: width,   // node card width
                        height: height,  // node card height
                        borderWidth: 1,  // node card border width
                        borderRadius: 15,  // node card border radius 
                        borderColor: {    // border color
                            red: 15,
                            green: 140,
                            blue: 121,
                            alpha: 1,
                        },
                        backgroundColor: {
                            red: 0,
                            green: 81,
                            blue: 90,
                            alpha: 1,
                        },
                        template: `<div style="text-align:center;
                        margin-top:30px;
                        font-size:30px;
                        font-weight:bold;
                   ">${d.name} <div>Failed ${count}</div> </div>`,
                        connectorLineColor: {
                            red: 11,
                            green: 123,
                            blue: 108,
                            alpha: 1
                        },
                        connectorLineWidth: 5,
                        dashArray: '',
                        expanded: expanded
                    }
                })
                setData(data1);
            })
    }

    //Method to get the count of each reason
    const getCount = (title, res) => {
        let count = 0;
        let filteredRes = res.filter(i => i.failedAt === title);
        count = filteredRes && filteredRes.length > 0 ? filteredRes[0].count : 0;
        return count;
    }

    useEffect(() => {
        getData();
    }, [props]);
    return (
        <div>
            <OrgChartComponent
                data={data}
            />
        </div>
    );
};

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
// import InfoIcon from '@material-ui/icons/Info';
// import Linechart from './LineChart';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "0%"
    },
    charts: {
        display: "flex",
    },
    box: {
        display: "flex",
        width: "100px",
        height: "35px",
        margin: "4px 10px 10px 10px",
        border: "1px solid grey",
        borderRadius: "3px",
        flexDirection: "column",
        padding: "0.5% 0 1% 1%"
    },
    text: {
        fontSize: "13px",
        color: "grey"
    },
    values: {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: '10px'
    },
    icon: {
        marginTop: "-10px",
        marginLeft: "2px"
    },
    title: {
        fontSize: "20px",
        padding: '10px 10px'
    },
    left: {
        marginLeft: "15px"
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // height: "calc(100% - 120px)"
    },
}))

function RoiView(props) {
    const classes = useStyles();
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    // const title = "5 year ROI projection based on current resolved %";
    // API call
    const apiCall = () => {
        setLoad(false);
        const basicAuth = props.config.authAPI.authHeaders;
        axios.get(props.config.cumulativeRoi.endpoint,
            { headers: { "Authorization": basicAuth } },
            //      {
            //     auth: {
            //         username: "admin",
            //         password: basicAuth
            //     }
            // }
        )
            .then((res) => {
                setData(res.data[0]);
                setTimeout(() => {
                    setLoad(true);
                }, 5000)
                // setLoad(true);
                // console.log(res);

            })
    }

    useEffect(() => {
        apiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={classes.container}>
            {/* <div className={classes.charts}> */}
            <div className={classes.title}>{props.config.cumulativeRoi.title} ​</div>
            <div className={classes.box}>
                <div className={classes.text}></div>
                <div className={classes.values}>{load && data !== [] ? <>{typeof data["ROI"] === "number" ? data["ROI"] % 1 === 0 ? `${data["ROI"]}%` : `${data["ROI"].toFixed(2)}%` : `${data["ROI"]}%`}</> : <div className={classes.loading}><CircularProgress size="20px" /></div>}</div>
            </div>

            {/* </div> */}
            {/* <div className={classes.charts}>
                <div><Linechart config={props.config} configData={props.config.RoiProfit.profit} /></div>
                <div><Linechart config={props.config} configData={props.config.RoiProfit.roi} /></div>
            </div> */}
        </div>
    )
}

export default RoiView;
/* Mindtree Confidential * © Mindtree Limited. All Rights Reserved. */
/* NOTICE: All content including but not limited to texts, website design, audio, video, software, trademarks, illustrations, photos, graphics, files, designs, arrangements etc. contained herein are sole and exclusive property of Mindtree Limited and are protected by copyright, trademark and other protective laws. Dissemination, reproduction modification or distribution of the content and material contained herein is strictly forbidden unless prior written permission is obtained from Mindtree. */
import React, { useState, useEffect } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import stylesConfig from '../../data/stylesConfig.json';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    download: {
        float: "right",
        margin: "-50px 40px 0px 0px"
    },
    notFound: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "120px",
        fontSize: "20px",
        color: "#0077b6"
    }
}))


function Linechart(props) {
    const classes = useStyles();
    const [Data, setData] = useState([]);
    const [download, setDownload] = useState([]);
    useEffect(() => {
        let data = [];
        downloadData(props.filter);
        data = props.data.sort(function (a, b) {
            return new Date(a.period) - new Date(b.period);
        });

        setData(data);
    }, [props])

    //Set color of the chart
    const fillColor = (index) => {
        let arr = [];
        arr = props.chartConfig.color.map(i => i)
        return arr[index]
    }

    //To download data in a csv file
    const downloadData = (filter) => {
        if (filter === 7 || filter === 30) {
            setDownload(props.data)
        } else {
            const basicAuth = props.header;
            axios.get(`${props.chartConfig.downloadUrl}/${filter}`,
                { headers: { "Authorization": basicAuth } },
                //      {
                //     auth: {
                //         username: "admin",
                //         password: basicAuth
                //     }
                // }
            )
                .then((res) => {
                    res.data.sort(function (a, b) {
                        return new Date(a.day) - new Date(b.day);
                    });
                    setDownload(res.data);
                    return res.data
                })
        }
    }
    const formatXAxis = (val) => {
        val = val.toString();
        return val.split("-", 2).join("-")
    }
    const renderColorfulLegendText = (value, entry) => {
        return <span style={{ color: 'black' }}>{value}</span>;
    };
    return (
        <>
            {download.length !== 0 && <CSVLink className={classes.download} filename={`AverageHandleTime_${props.date}.csv`} data={download} separator={","}>
                <GetAppIcon style={{ fill: "#0077b6" }} />
            </CSVLink>}
            <LineChart
                width={stylesConfig.AHT.chartWidth}
                height={stylesConfig.AHT.chartHeight}
                data={Data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={{ fontSize: "13px" }} height={45} tickFormatter={formatXAxis} dataKey={props.chartConfig.xaxis} />
                <YAxis tick={{ fontSize: "13px" }} label={{ value: 'In Minutes', angle: -90, fontSize: "12px", dy: -10, position: "insideLeft" }} />
                <Tooltip />
                <Legend formatter={renderColorfulLegendText} />
                {props.chartConfig.yaxis.map((i, index) => {
                    return <Line type="monotone" key={index} dataKey={i} stroke={fillColor(index)} activeDot={{ r: 8 }} />
                })}
            </LineChart>

        </>
    );
}

export default Linechart;